// this file is generated from the shared currencies tsv file, do not modify
import type { CurrencyPairData } from '../state/referenceData/referenceDataModel';

export const currencies: Record<string, CurrencyPairData> = {
  'AED/CNH': {
    pair: 'AED/CNH',
    ccy1: 'AED',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/HKD': {
    pair: 'AED/HKD',
    ccy1: 'AED',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/JPY': {
    pair: 'AED/JPY',
    ccy1: 'AED',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/NOK': {
    pair: 'AED/NOK',
    ccy1: 'AED',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/PLN': {
    pair: 'AED/PLN',
    ccy1: 'AED',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/SEK': {
    pair: 'AED/SEK',
    ccy1: 'AED',
    ccy2: 'SEK',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/HUF': {
    pair: 'ARS/HUF',
    ccy1: 'ARS',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/JPY': {
    pair: 'ARS/JPY',
    ccy1: 'ARS',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/AED': {
    pair: 'AUD/AED',
    ccy1: 'AUD',
    ccy2: 'AED',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/ARS': {
    pair: 'AUD/ARS',
    ccy1: 'AUD',
    ccy2: 'ARS',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/BRL': {
    pair: 'AUD/BRL',
    ccy1: 'AUD',
    ccy2: 'BRL',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/CAD': {
    pair: 'AUD/CAD',
    ccy1: 'AUD',
    ccy2: 'CAD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'AUD/CHF': {
    pair: 'AUD/CHF',
    ccy1: 'AUD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'AUD/CLP': {
    pair: 'AUD/CLP',
    ccy1: 'AUD',
    ccy2: 'CLP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/CNH': {
    pair: 'AUD/CNH',
    ccy1: 'AUD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/CNY': {
    pair: 'AUD/CNY',
    ccy1: 'AUD',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/COP': {
    pair: 'AUD/COP',
    ccy1: 'AUD',
    ccy2: 'COP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/CZK': {
    pair: 'AUD/CZK',
    ccy1: 'AUD',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/DKK': {
    pair: 'AUD/DKK',
    ccy1: 'AUD',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/EGP': {
    pair: 'AUD/EGP',
    ccy1: 'AUD',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/HKD': {
    pair: 'AUD/HKD',
    ccy1: 'AUD',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/HUF': {
    pair: 'AUD/HUF',
    ccy1: 'AUD',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/IDR': {
    pair: 'AUD/IDR',
    ccy1: 'AUD',
    ccy2: 'IDR',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/ILS': {
    pair: 'AUD/ILS',
    ccy1: 'AUD',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/INR': {
    pair: 'AUD/INR',
    ccy1: 'AUD',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/JPY': {
    pair: 'AUD/JPY',
    ccy1: 'AUD',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'AUD/KRW': {
    pair: 'AUD/KRW',
    ccy1: 'AUD',
    ccy2: 'KRW',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/KZT': {
    pair: 'AUD/KZT',
    ccy1: 'AUD',
    ccy2: 'KZT',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/MAD': {
    pair: 'AUD/MAD',
    ccy1: 'AUD',
    ccy2: 'MAD',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/MXN': {
    pair: 'AUD/MXN',
    ccy1: 'AUD',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/NGN': {
    pair: 'AUD/NGN',
    ccy1: 'AUD',
    ccy2: 'NGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/NOK': {
    pair: 'AUD/NOK',
    ccy1: 'AUD',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'AUD/NZD': {
    pair: 'AUD/NZD',
    ccy1: 'AUD',
    ccy2: 'NZD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'AUD/PEN': {
    pair: 'AUD/PEN',
    ccy1: 'AUD',
    ccy2: 'PEN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/PHP': {
    pair: 'AUD/PHP',
    ccy1: 'AUD',
    ccy2: 'PHP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/PLN': {
    pair: 'AUD/PLN',
    ccy1: 'AUD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/RUB': {
    pair: 'AUD/RUB',
    ccy1: 'AUD',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/SEK': {
    pair: 'AUD/SEK',
    ccy1: 'AUD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'AUD/SGD': {
    pair: 'AUD/SGD',
    ccy1: 'AUD',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'AUD/THB': {
    pair: 'AUD/THB',
    ccy1: 'AUD',
    ccy2: 'THB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/TRY': {
    pair: 'AUD/TRY',
    ccy1: 'AUD',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/TWD': {
    pair: 'AUD/TWD',
    ccy1: 'AUD',
    ccy2: 'TWD',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/UAH': {
    pair: 'AUD/UAH',
    ccy1: 'AUD',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/USD': {
    pair: 'AUD/USD',
    ccy1: 'AUD',
    ccy2: 'USD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'AUD/VND': {
    pair: 'AUD/VND',
    ccy1: 'AUD',
    ccy2: 'VND',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/ZAR': {
    pair: 'AUD/ZAR',
    ccy1: 'AUD',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BGN/PLN': {
    pair: 'BGN/PLN',
    ccy1: 'BGN',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BGN/RON': {
    pair: 'BGN/RON',
    ccy1: 'BGN',
    ccy2: 'RON',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/CLP': {
    pair: 'BRL/CLP',
    ccy1: 'BRL',
    ccy2: 'CLP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/DKK': {
    pair: 'BRL/DKK',
    ccy1: 'BRL',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/JPY': {
    pair: 'BRL/JPY',
    ccy1: 'BRL',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/MXN': {
    pair: 'BRL/MXN',
    ccy1: 'BRL',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/AED': {
    pair: 'CAD/AED',
    ccy1: 'CAD',
    ccy2: 'AED',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/ARS': {
    pair: 'CAD/ARS',
    ccy1: 'CAD',
    ccy2: 'ARS',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/BGN': {
    pair: 'CAD/BGN',
    ccy1: 'CAD',
    ccy2: 'BGN',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/BHD': {
    pair: 'CAD/BHD',
    ccy1: 'CAD',
    ccy2: 'BHD',
    precision: 6,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/BRL': {
    pair: 'CAD/BRL',
    ccy1: 'CAD',
    ccy2: 'BRL',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/CHF': {
    pair: 'CAD/CHF',
    ccy1: 'CAD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'CAD/CLP': {
    pair: 'CAD/CLP',
    ccy1: 'CAD',
    ccy2: 'CLP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/CNH': {
    pair: 'CAD/CNH',
    ccy1: 'CAD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/CNY': {
    pair: 'CAD/CNY',
    ccy1: 'CAD',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/COP': {
    pair: 'CAD/COP',
    ccy1: 'CAD',
    ccy2: 'COP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/CZK': {
    pair: 'CAD/CZK',
    ccy1: 'CAD',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/DKK': {
    pair: 'CAD/DKK',
    ccy1: 'CAD',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/EGP': {
    pair: 'CAD/EGP',
    ccy1: 'CAD',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/HKD': {
    pair: 'CAD/HKD',
    ccy1: 'CAD',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/HUF': {
    pair: 'CAD/HUF',
    ccy1: 'CAD',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/IDR': {
    pair: 'CAD/IDR',
    ccy1: 'CAD',
    ccy2: 'IDR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/ILS': {
    pair: 'CAD/ILS',
    ccy1: 'CAD',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/INR': {
    pair: 'CAD/INR',
    ccy1: 'CAD',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/JPY': {
    pair: 'CAD/JPY',
    ccy1: 'CAD',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'CAD/KRW': {
    pair: 'CAD/KRW',
    ccy1: 'CAD',
    ccy2: 'KRW',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/KWD': {
    pair: 'CAD/KWD',
    ccy1: 'CAD',
    ccy2: 'KWD',
    precision: 6,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/KZT': {
    pair: 'CAD/KZT',
    ccy1: 'CAD',
    ccy2: 'KZT',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/MXN': {
    pair: 'CAD/MXN',
    ccy1: 'CAD',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/NGN': {
    pair: 'CAD/NGN',
    ccy1: 'CAD',
    ccy2: 'NGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/NOK': {
    pair: 'CAD/NOK',
    ccy1: 'CAD',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'CAD/PEN': {
    pair: 'CAD/PEN',
    ccy1: 'CAD',
    ccy2: 'PEN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/PHP': {
    pair: 'CAD/PHP',
    ccy1: 'CAD',
    ccy2: 'PHP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/PLN': {
    pair: 'CAD/PLN',
    ccy1: 'CAD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/QAR': {
    pair: 'CAD/QAR',
    ccy1: 'CAD',
    ccy2: 'QAR',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/RON': {
    pair: 'CAD/RON',
    ccy1: 'CAD',
    ccy2: 'RON',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/RUB': {
    pair: 'CAD/RUB',
    ccy1: 'CAD',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/SAR': {
    pair: 'CAD/SAR',
    ccy1: 'CAD',
    ccy2: 'SAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/SEK': {
    pair: 'CAD/SEK',
    ccy1: 'CAD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'CAD/SGD': {
    pair: 'CAD/SGD',
    ccy1: 'CAD',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/THB': {
    pair: 'CAD/THB',
    ccy1: 'CAD',
    ccy2: 'THB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/TRY': {
    pair: 'CAD/TRY',
    ccy1: 'CAD',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/TWD': {
    pair: 'CAD/TWD',
    ccy1: 'CAD',
    ccy2: 'TWD',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/UAH': {
    pair: 'CAD/UAH',
    ccy1: 'CAD',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/VND': {
    pair: 'CAD/VND',
    ccy1: 'CAD',
    ccy2: 'VND',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/ZAR': {
    pair: 'CAD/ZAR',
    ccy1: 'CAD',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/AED': {
    pair: 'CHF/AED',
    ccy1: 'CHF',
    ccy2: 'AED',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/ARS': {
    pair: 'CHF/ARS',
    ccy1: 'CHF',
    ccy2: 'ARS',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/BGN': {
    pair: 'CHF/BGN',
    ccy1: 'CHF',
    ccy2: 'BGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/BRL': {
    pair: 'CHF/BRL',
    ccy1: 'CHF',
    ccy2: 'BRL',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/CLP': {
    pair: 'CHF/CLP',
    ccy1: 'CHF',
    ccy2: 'CLP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/CNH': {
    pair: 'CHF/CNH',
    ccy1: 'CHF',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/CNY': {
    pair: 'CHF/CNY',
    ccy1: 'CHF',
    ccy2: 'CNY',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/COP': {
    pair: 'CHF/COP',
    ccy1: 'CHF',
    ccy2: 'COP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/CZK': {
    pair: 'CHF/CZK',
    ccy1: 'CHF',
    ccy2: 'CZK',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/DKK': {
    pair: 'CHF/DKK',
    ccy1: 'CHF',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/EGP': {
    pair: 'CHF/EGP',
    ccy1: 'CHF',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/GHS': {
    pair: 'CHF/GHS',
    ccy1: 'CHF',
    ccy2: 'GHS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/HKD': {
    pair: 'CHF/HKD',
    ccy1: 'CHF',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/HRK': {
    pair: 'CHF/HRK',
    ccy1: 'CHF',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/HUF': {
    pair: 'CHF/HUF',
    ccy1: 'CHF',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/IDR': {
    pair: 'CHF/IDR',
    ccy1: 'CHF',
    ccy2: 'IDR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/ILS': {
    pair: 'CHF/ILS',
    ccy1: 'CHF',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/INR': {
    pair: 'CHF/INR',
    ccy1: 'CHF',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/JPY': {
    pair: 'CHF/JPY',
    ccy1: 'CHF',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'CHF/KES': {
    pair: 'CHF/KES',
    ccy1: 'CHF',
    ccy2: 'KES',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/KRW': {
    pair: 'CHF/KRW',
    ccy1: 'CHF',
    ccy2: 'KRW',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/KWD': {
    pair: 'CHF/KWD',
    ccy1: 'CHF',
    ccy2: 'KWD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/KZT': {
    pair: 'CHF/KZT',
    ccy1: 'CHF',
    ccy2: 'KZT',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/MAD': {
    pair: 'CHF/MAD',
    ccy1: 'CHF',
    ccy2: 'MAD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/MXN': {
    pair: 'CHF/MXN',
    ccy1: 'CHF',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/NGN': {
    pair: 'CHF/NGN',
    ccy1: 'CHF',
    ccy2: 'NGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/NOK': {
    pair: 'CHF/NOK',
    ccy1: 'CHF',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'CHF/PEN': {
    pair: 'CHF/PEN',
    ccy1: 'CHF',
    ccy2: 'PEN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/PHP': {
    pair: 'CHF/PHP',
    ccy1: 'CHF',
    ccy2: 'PHP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/PLN': {
    pair: 'CHF/PLN',
    ccy1: 'CHF',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/QAR': {
    pair: 'CHF/QAR',
    ccy1: 'CHF',
    ccy2: 'QAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/RON': {
    pair: 'CHF/RON',
    ccy1: 'CHF',
    ccy2: 'RON',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/RSD': {
    pair: 'CHF/RSD',
    ccy1: 'CHF',
    ccy2: 'RSD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/RUB': {
    pair: 'CHF/RUB',
    ccy1: 'CHF',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/SEK': {
    pair: 'CHF/SEK',
    ccy1: 'CHF',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'CHF/SGD': {
    pair: 'CHF/SGD',
    ccy1: 'CHF',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/THB': {
    pair: 'CHF/THB',
    ccy1: 'CHF',
    ccy2: 'THB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/TRY': {
    pair: 'CHF/TRY',
    ccy1: 'CHF',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/TWD': {
    pair: 'CHF/TWD',
    ccy1: 'CHF',
    ccy2: 'TWD',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/UAH': {
    pair: 'CHF/UAH',
    ccy1: 'CHF',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/VND': {
    pair: 'CHF/VND',
    ccy1: 'CHF',
    ccy2: 'VND',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/ZAR': {
    pair: 'CHF/ZAR',
    ccy1: 'CHF',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/BRL': {
    pair: 'CNH/BRL',
    ccy1: 'CNH',
    ccy2: 'BRL',
    precision: 4,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/CZK': {
    pair: 'CNH/CZK',
    ccy1: 'CNH',
    ccy2: 'CZK',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/HKD': {
    pair: 'CNH/HKD',
    ccy1: 'CNH',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/HUF': {
    pair: 'CNH/HUF',
    ccy1: 'CNH',
    ccy2: 'HUF',
    precision: 2,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/IDR': {
    pair: 'CNH/IDR',
    ccy1: 'CNH',
    ccy2: 'IDR',
    precision: 2,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/INR': {
    pair: 'CNH/INR',
    ccy1: 'CNH',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/JPY': {
    pair: 'CNH/JPY',
    ccy1: 'CNH',
    ccy2: 'JPY',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/KRW': {
    pair: 'CNH/KRW',
    ccy1: 'CNH',
    ccy2: 'KRW',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/MXN': {
    pair: 'CNH/MXN',
    ccy1: 'CNH',
    ccy2: 'MXN',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: false
  },
  'CNH/PHP': {
    pair: 'CNH/PHP',
    ccy1: 'CNH',
    ccy2: 'PHP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/RUB': {
    pair: 'CNH/RUB',
    ccy1: 'CNH',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/TWD': {
    pair: 'CNH/TWD',
    ccy1: 'CNH',
    ccy2: 'TWD',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/JPY': {
    pair: 'CNY/JPY',
    ccy1: 'CNY',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/HRK': {
    pair: 'CZK/HRK',
    ccy1: 'CZK',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/HUF': {
    pair: 'CZK/HUF',
    ccy1: 'CZK',
    ccy2: 'HUF',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/JPY': {
    pair: 'CZK/JPY',
    ccy1: 'CZK',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/PLN': {
    pair: 'CZK/PLN',
    ccy1: 'CZK',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/RON': {
    pair: 'CZK/RON',
    ccy1: 'CZK',
    ccy2: 'RON',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/SEK': {
    pair: 'CZK/SEK',
    ccy1: 'CZK',
    ccy2: 'SEK',
    precision: 6,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/SGD': {
    pair: 'CZK/SGD',
    ccy1: 'CZK',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/ZAR': {
    pair: 'CZK/ZAR',
    ccy1: 'CZK',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/ARS': {
    pair: 'DKK/ARS',
    ccy1: 'DKK',
    ccy2: 'ARS',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/CLP': {
    pair: 'DKK/CLP',
    ccy1: 'DKK',
    ccy2: 'CLP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/CNH': {
    pair: 'DKK/CNH',
    ccy1: 'DKK',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/CNY': {
    pair: 'DKK/CNY',
    ccy1: 'DKK',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/COP': {
    pair: 'DKK/COP',
    ccy1: 'DKK',
    ccy2: 'COP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/CZK': {
    pair: 'DKK/CZK',
    ccy1: 'DKK',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/EGP': {
    pair: 'DKK/EGP',
    ccy1: 'DKK',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/HKD': {
    pair: 'DKK/HKD',
    ccy1: 'DKK',
    ccy2: 'HKD',
    precision: 6,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/HUF': {
    pair: 'DKK/HUF',
    ccy1: 'DKK',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/IDR': {
    pair: 'DKK/IDR',
    ccy1: 'DKK',
    ccy2: 'IDR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/INR': {
    pair: 'DKK/INR',
    ccy1: 'DKK',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/JPY': {
    pair: 'DKK/JPY',
    ccy1: 'DKK',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/KRW': {
    pair: 'DKK/KRW',
    ccy1: 'DKK',
    ccy2: 'KRW',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/KZT': {
    pair: 'DKK/KZT',
    ccy1: 'DKK',
    ccy2: 'KZT',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/NGN': {
    pair: 'DKK/NGN',
    ccy1: 'DKK',
    ccy2: 'NGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/PEN': {
    pair: 'DKK/PEN',
    ccy1: 'DKK',
    ccy2: 'PEN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/PHP': {
    pair: 'DKK/PHP',
    ccy1: 'DKK',
    ccy2: 'PHP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/RON': {
    pair: 'DKK/RON',
    ccy1: 'DKK',
    ccy2: 'RON',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/RUB': {
    pair: 'DKK/RUB',
    ccy1: 'DKK',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/SEK': {
    pair: 'DKK/SEK',
    ccy1: 'DKK',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/SGD': {
    pair: 'DKK/SGD',
    ccy1: 'DKK',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/TRY': {
    pair: 'DKK/TRY',
    ccy1: 'DKK',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/TWD': {
    pair: 'DKK/TWD',
    ccy1: 'DKK',
    ccy2: 'TWD',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/UAH': {
    pair: 'DKK/UAH',
    ccy1: 'DKK',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/VND': {
    pair: 'DKK/VND',
    ccy1: 'DKK',
    ccy2: 'VND',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/AED': {
    pair: 'EUR/AED',
    ccy1: 'EUR',
    ccy2: 'AED',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/ARS': {
    pair: 'EUR/ARS',
    ccy1: 'EUR',
    ccy2: 'ARS',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/AUD': {
    pair: 'EUR/AUD',
    ccy1: 'EUR',
    ccy2: 'AUD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/BGN': {
    pair: 'EUR/BGN',
    ccy1: 'EUR',
    ccy2: 'BGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/BHD': {
    pair: 'EUR/BHD',
    ccy1: 'EUR',
    ccy2: 'BHD',
    precision: 6,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/BRL': {
    pair: 'EUR/BRL',
    ccy1: 'EUR',
    ccy2: 'BRL',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/CAD': {
    pair: 'EUR/CAD',
    ccy1: 'EUR',
    ccy2: 'CAD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'EUR/CHF': {
    pair: 'EUR/CHF',
    ccy1: 'EUR',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'EUR/CLP': {
    pair: 'EUR/CLP',
    ccy1: 'EUR',
    ccy2: 'CLP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/CNH': {
    pair: 'EUR/CNH',
    ccy1: 'EUR',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/CNY': {
    pair: 'EUR/CNY',
    ccy1: 'EUR',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/COP': {
    pair: 'EUR/COP',
    ccy1: 'EUR',
    ccy2: 'COP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/CZK': {
    pair: 'EUR/CZK',
    ccy1: 'EUR',
    ccy2: 'CZK',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/DKK': {
    pair: 'EUR/DKK',
    ccy1: 'EUR',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/DZD': {
    pair: 'EUR/DZD',
    ccy1: 'EUR',
    ccy2: 'DZD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/EGP': {
    pair: 'EUR/EGP',
    ccy1: 'EUR',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/GBP': {
    pair: 'EUR/GBP',
    ccy1: 'EUR',
    ccy2: 'GBP',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'EUR/GHS': {
    pair: 'EUR/GHS',
    ccy1: 'EUR',
    ccy2: 'GHS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/HKD': {
    pair: 'EUR/HKD',
    ccy1: 'EUR',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/HRK': {
    pair: 'EUR/HRK',
    ccy1: 'EUR',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/HUF': {
    pair: 'EUR/HUF',
    ccy1: 'EUR',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/IDR': {
    pair: 'EUR/IDR',
    ccy1: 'EUR',
    ccy2: 'IDR',
    precision: 1,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/ILS': {
    pair: 'EUR/ILS',
    ccy1: 'EUR',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/INR': {
    pair: 'EUR/INR',
    ccy1: 'EUR',
    ccy2: 'INR',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/ISK': {
    pair: 'EUR/ISK',
    ccy1: 'EUR',
    ccy2: 'ISK',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/JOD': {
    pair: 'EUR/JOD',
    ccy1: 'EUR',
    ccy2: 'JOD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/JPY': {
    pair: 'EUR/JPY',
    ccy1: 'EUR',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'EUR/KES': {
    pair: 'EUR/KES',
    ccy1: 'EUR',
    ccy2: 'KES',
    precision: 2,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/KRW': {
    pair: 'EUR/KRW',
    ccy1: 'EUR',
    ccy2: 'KRW',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/KWD': {
    pair: 'EUR/KWD',
    ccy1: 'EUR',
    ccy2: 'KWD',
    precision: 6,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/KZT': {
    pair: 'EUR/KZT',
    ccy1: 'EUR',
    ccy2: 'KZT',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/MAD': {
    pair: 'EUR/MAD',
    ccy1: 'EUR',
    ccy2: 'MAD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/MUR': {
    pair: 'EUR/MUR',
    ccy1: 'EUR',
    ccy2: 'MUR',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/MXN': {
    pair: 'EUR/MXN',
    ccy1: 'EUR',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/MZN': {
    pair: 'EUR/MZN',
    ccy1: 'EUR',
    ccy2: 'MZN',
    precision: 2,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/NGN': {
    pair: 'EUR/NGN',
    ccy1: 'EUR',
    ccy2: 'NGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/NOK': {
    pair: 'EUR/NOK',
    ccy1: 'EUR',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/NZD': {
    pair: 'EUR/NZD',
    ccy1: 'EUR',
    ccy2: 'NZD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/OMR': {
    pair: 'EUR/OMR',
    ccy1: 'EUR',
    ccy2: 'OMR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/PEN': {
    pair: 'EUR/PEN',
    ccy1: 'EUR',
    ccy2: 'PEN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/PHP': {
    pair: 'EUR/PHP',
    ccy1: 'EUR',
    ccy2: 'PHP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/PLN': {
    pair: 'EUR/PLN',
    ccy1: 'EUR',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/QAR': {
    pair: 'EUR/QAR',
    ccy1: 'EUR',
    ccy2: 'QAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/RON': {
    pair: 'EUR/RON',
    ccy1: 'EUR',
    ccy2: 'RON',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/RSD': {
    pair: 'EUR/RSD',
    ccy1: 'EUR',
    ccy2: 'RSD',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/RUB': {
    pair: 'EUR/RUB',
    ccy1: 'EUR',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/SAR': {
    pair: 'EUR/SAR',
    ccy1: 'EUR',
    ccy2: 'SAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/SEK': {
    pair: 'EUR/SEK',
    ccy1: 'EUR',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'EUR/SGD': {
    pair: 'EUR/SGD',
    ccy1: 'EUR',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/THB': {
    pair: 'EUR/THB',
    ccy1: 'EUR',
    ccy2: 'THB',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/TND': {
    pair: 'EUR/TND',
    ccy1: 'EUR',
    ccy2: 'TND',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/TRY': {
    pair: 'EUR/TRY',
    ccy1: 'EUR',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'EUR/TWD': {
    pair: 'EUR/TWD',
    ccy1: 'EUR',
    ccy2: 'TWD',
    precision: 4,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/UAH': {
    pair: 'EUR/UAH',
    ccy1: 'EUR',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/USD': {
    pair: 'EUR/USD',
    ccy1: 'EUR',
    ccy2: 'USD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'EUR/VND': {
    pair: 'EUR/VND',
    ccy1: 'EUR',
    ccy2: 'VND',
    precision: 1,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/XAF': {
    pair: 'EUR/XAF',
    ccy1: 'EUR',
    ccy2: 'XAF',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/XOF': {
    pair: 'EUR/XOF',
    ccy1: 'EUR',
    ccy2: 'XOF',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/ZAR': {
    pair: 'EUR/ZAR',
    ccy1: 'EUR',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'GBP/AED': {
    pair: 'GBP/AED',
    ccy1: 'GBP',
    ccy2: 'AED',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/ARS': {
    pair: 'GBP/ARS',
    ccy1: 'GBP',
    ccy2: 'ARS',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/AUD': {
    pair: 'GBP/AUD',
    ccy1: 'GBP',
    ccy2: 'AUD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'GBP/BGN': {
    pair: 'GBP/BGN',
    ccy1: 'GBP',
    ccy2: 'BGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/BRL': {
    pair: 'GBP/BRL',
    ccy1: 'GBP',
    ccy2: 'BRL',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/CAD': {
    pair: 'GBP/CAD',
    ccy1: 'GBP',
    ccy2: 'CAD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'GBP/CHF': {
    pair: 'GBP/CHF',
    ccy1: 'GBP',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'GBP/CLP': {
    pair: 'GBP/CLP',
    ccy1: 'GBP',
    ccy2: 'CLP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/CNH': {
    pair: 'GBP/CNH',
    ccy1: 'GBP',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/CNY': {
    pair: 'GBP/CNY',
    ccy1: 'GBP',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/COP': {
    pair: 'GBP/COP',
    ccy1: 'GBP',
    ccy2: 'COP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/CZK': {
    pair: 'GBP/CZK',
    ccy1: 'GBP',
    ccy2: 'CZK',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/DKK': {
    pair: 'GBP/DKK',
    ccy1: 'GBP',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/EGP': {
    pair: 'GBP/EGP',
    ccy1: 'GBP',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/GHS': {
    pair: 'GBP/GHS',
    ccy1: 'GBP',
    ccy2: 'GHS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/HKD': {
    pair: 'GBP/HKD',
    ccy1: 'GBP',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/HRK': {
    pair: 'GBP/HRK',
    ccy1: 'GBP',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/HUF': {
    pair: 'GBP/HUF',
    ccy1: 'GBP',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/IDR': {
    pair: 'GBP/IDR',
    ccy1: 'GBP',
    ccy2: 'IDR',
    precision: 1,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/ILS': {
    pair: 'GBP/ILS',
    ccy1: 'GBP',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/INR': {
    pair: 'GBP/INR',
    ccy1: 'GBP',
    ccy2: 'INR',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/JPY': {
    pair: 'GBP/JPY',
    ccy1: 'GBP',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'GBP/KES': {
    pair: 'GBP/KES',
    ccy1: 'GBP',
    ccy2: 'KES',
    precision: 2,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/KRW': {
    pair: 'GBP/KRW',
    ccy1: 'GBP',
    ccy2: 'KRW',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/KWD': {
    pair: 'GBP/KWD',
    ccy1: 'GBP',
    ccy2: 'KWD',
    precision: 6,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/KZT': {
    pair: 'GBP/KZT',
    ccy1: 'GBP',
    ccy2: 'KZT',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/MAD': {
    pair: 'GBP/MAD',
    ccy1: 'GBP',
    ccy2: 'MAD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/MXN': {
    pair: 'GBP/MXN',
    ccy1: 'GBP',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/NGN': {
    pair: 'GBP/NGN',
    ccy1: 'GBP',
    ccy2: 'NGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/NOK': {
    pair: 'GBP/NOK',
    ccy1: 'GBP',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'GBP/NZD': {
    pair: 'GBP/NZD',
    ccy1: 'GBP',
    ccy2: 'NZD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'GBP/PEN': {
    pair: 'GBP/PEN',
    ccy1: 'GBP',
    ccy2: 'PEN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/PHP': {
    pair: 'GBP/PHP',
    ccy1: 'GBP',
    ccy2: 'PHP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/PLN': {
    pair: 'GBP/PLN',
    ccy1: 'GBP',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/QAR': {
    pair: 'GBP/QAR',
    ccy1: 'GBP',
    ccy2: 'QAR',
    precision: 4,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/RON': {
    pair: 'GBP/RON',
    ccy1: 'GBP',
    ccy2: 'RON',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/RUB': {
    pair: 'GBP/RUB',
    ccy1: 'GBP',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/SAR': {
    pair: 'GBP/SAR',
    ccy1: 'GBP',
    ccy2: 'SAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/SEK': {
    pair: 'GBP/SEK',
    ccy1: 'GBP',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'GBP/SGD': {
    pair: 'GBP/SGD',
    ccy1: 'GBP',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'GBP/THB': {
    pair: 'GBP/THB',
    ccy1: 'GBP',
    ccy2: 'THB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/TND': {
    pair: 'GBP/TND',
    ccy1: 'GBP',
    ccy2: 'TND',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/TRY': {
    pair: 'GBP/TRY',
    ccy1: 'GBP',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/TWD': {
    pair: 'GBP/TWD',
    ccy1: 'GBP',
    ccy2: 'TWD',
    precision: 4,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/UAH': {
    pair: 'GBP/UAH',
    ccy1: 'GBP',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/USD': {
    pair: 'GBP/USD',
    ccy1: 'GBP',
    ccy2: 'USD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'GBP/VND': {
    pair: 'GBP/VND',
    ccy1: 'GBP',
    ccy2: 'VND',
    precision: 1,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/ZAR': {
    pair: 'GBP/ZAR',
    ccy1: 'GBP',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/BRL': {
    pair: 'HKD/BRL',
    ccy1: 'HKD',
    ccy2: 'BRL',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/CNY': {
    pair: 'HKD/CNY',
    ccy1: 'HKD',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/CZK': {
    pair: 'HKD/CZK',
    ccy1: 'HKD',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/DKK': {
    pair: 'HKD/DKK',
    ccy1: 'HKD',
    ccy2: 'DKK',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/HUF': {
    pair: 'HKD/HUF',
    ccy1: 'HKD',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/INR': {
    pair: 'HKD/INR',
    ccy1: 'HKD',
    ccy2: 'INR',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/JPY': {
    pair: 'HKD/JPY',
    ccy1: 'HKD',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/KRW': {
    pair: 'HKD/KRW',
    ccy1: 'HKD',
    ccy2: 'KRW',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/KWD': {
    pair: 'HKD/KWD',
    ccy1: 'HKD',
    ccy2: 'KWD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/NOK': {
    pair: 'HKD/NOK',
    ccy1: 'HKD',
    ccy2: 'NOK',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/SEK': {
    pair: 'HKD/SEK',
    ccy1: 'HKD',
    ccy2: 'SEK',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/THB': {
    pair: 'HKD/THB',
    ccy1: 'HKD',
    ccy2: 'THB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/TWD': {
    pair: 'HKD/TWD',
    ccy1: 'HKD',
    ccy2: 'TWD',
    precision: 4,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/BRL': {
    pair: 'HUF/BRL',
    ccy1: 'HUF',
    ccy2: 'BRL',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/CLP': {
    pair: 'HUF/CLP',
    ccy1: 'HUF',
    ccy2: 'CLP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/CNY': {
    pair: 'HUF/CNY',
    ccy1: 'HUF',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/COP': {
    pair: 'HUF/COP',
    ccy1: 'HUF',
    ccy2: 'COP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/EGP': {
    pair: 'HUF/EGP',
    ccy1: 'HUF',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/HRK': {
    pair: 'HUF/HRK',
    ccy1: 'HUF',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/IDR': {
    pair: 'HUF/IDR',
    ccy1: 'HUF',
    ccy2: 'IDR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/INR': {
    pair: 'HUF/INR',
    ccy1: 'HUF',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/JPY': {
    pair: 'HUF/JPY',
    ccy1: 'HUF',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/KRW': {
    pair: 'HUF/KRW',
    ccy1: 'HUF',
    ccy2: 'KRW',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/KZT': {
    pair: 'HUF/KZT',
    ccy1: 'HUF',
    ccy2: 'KZT',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/NGN': {
    pair: 'HUF/NGN',
    ccy1: 'HUF',
    ccy2: 'NGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/PEN': {
    pair: 'HUF/PEN',
    ccy1: 'HUF',
    ccy2: 'PEN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/PHP': {
    pair: 'HUF/PHP',
    ccy1: 'HUF',
    ccy2: 'PHP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/RON': {
    pair: 'HUF/RON',
    ccy1: 'HUF',
    ccy2: 'RON',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/TWD': {
    pair: 'HUF/TWD',
    ccy1: 'HUF',
    ccy2: 'TWD',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/UAH': {
    pair: 'HUF/UAH',
    ccy1: 'HUF',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/VND': {
    pair: 'HUF/VND',
    ccy1: 'HUF',
    ccy2: 'VND',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/ZAR': {
    pair: 'HUF/ZAR',
    ccy1: 'HUF',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ILS/HUF': {
    pair: 'ILS/HUF',
    ccy1: 'ILS',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ILS/JPY': {
    pair: 'ILS/JPY',
    ccy1: 'ILS',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/JPY': {
    pair: 'INR/JPY',
    ccy1: 'INR',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/BGN': {
    pair: 'JPY/BGN',
    ccy1: 'JPY',
    ccy2: 'BGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/CLP': {
    pair: 'JPY/CLP',
    ccy1: 'JPY',
    ccy2: 'CLP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/COP': {
    pair: 'JPY/COP',
    ccy1: 'JPY',
    ccy2: 'COP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/EGP': {
    pair: 'JPY/EGP',
    ccy1: 'JPY',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/GHS': {
    pair: 'JPY/GHS',
    ccy1: 'JPY',
    ccy2: 'GHS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/HRK': {
    pair: 'JPY/HRK',
    ccy1: 'JPY',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/IDR': {
    pair: 'JPY/IDR',
    ccy1: 'JPY',
    ccy2: 'IDR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/INR': {
    pair: 'JPY/INR',
    ccy1: 'JPY',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/KES': {
    pair: 'JPY/KES',
    ccy1: 'JPY',
    ccy2: 'KES',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/KRW': {
    pair: 'JPY/KRW',
    ccy1: 'JPY',
    ccy2: 'KRW',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/KWD': {
    pair: 'JPY/KWD',
    ccy1: 'JPY',
    ccy2: 'KWD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/KZT': {
    pair: 'JPY/KZT',
    ccy1: 'JPY',
    ccy2: 'KZT',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/MAD': {
    pair: 'JPY/MAD',
    ccy1: 'JPY',
    ccy2: 'MAD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/NGN': {
    pair: 'JPY/NGN',
    ccy1: 'JPY',
    ccy2: 'NGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/QAR': {
    pair: 'JPY/QAR',
    ccy1: 'JPY',
    ccy2: 'QAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/RON': {
    pair: 'JPY/RON',
    ccy1: 'JPY',
    ccy2: 'RON',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/RSD': {
    pair: 'JPY/RSD',
    ccy1: 'JPY',
    ccy2: 'RSD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/SAR': {
    pair: 'JPY/SAR',
    ccy1: 'JPY',
    ccy2: 'SAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/TND': {
    pair: 'JPY/TND',
    ccy1: 'JPY',
    ccy2: 'TND',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/UAH': {
    pair: 'JPY/UAH',
    ccy1: 'JPY',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/VND': {
    pair: 'JPY/VND',
    ccy1: 'JPY',
    ccy2: 'VND',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MAD/SEK': {
    pair: 'MAD/SEK',
    ccy1: 'MAD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MXN/HKD': {
    pair: 'MXN/HKD',
    ccy1: 'MXN',
    ccy2: 'HKD',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MXN/JPY': {
    pair: 'MXN/JPY',
    ccy1: 'MXN',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/ARS': {
    pair: 'NOK/ARS',
    ccy1: 'NOK',
    ccy2: 'ARS',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/BRL': {
    pair: 'NOK/BRL',
    ccy1: 'NOK',
    ccy2: 'BRL',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/CLP': {
    pair: 'NOK/CLP',
    ccy1: 'NOK',
    ccy2: 'CLP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/CNH': {
    pair: 'NOK/CNH',
    ccy1: 'NOK',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/CNY': {
    pair: 'NOK/CNY',
    ccy1: 'NOK',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/COP': {
    pair: 'NOK/COP',
    ccy1: 'NOK',
    ccy2: 'COP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/CZK': {
    pair: 'NOK/CZK',
    ccy1: 'NOK',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/DKK': {
    pair: 'NOK/DKK',
    ccy1: 'NOK',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/EGP': {
    pair: 'NOK/EGP',
    ccy1: 'NOK',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/HKD': {
    pair: 'NOK/HKD',
    ccy1: 'NOK',
    ccy2: 'HKD',
    precision: 6,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/HUF': {
    pair: 'NOK/HUF',
    ccy1: 'NOK',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/IDR': {
    pair: 'NOK/IDR',
    ccy1: 'NOK',
    ccy2: 'IDR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/INR': {
    pair: 'NOK/INR',
    ccy1: 'NOK',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/JPY': {
    pair: 'NOK/JPY',
    ccy1: 'NOK',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'NOK/KRW': {
    pair: 'NOK/KRW',
    ccy1: 'NOK',
    ccy2: 'KRW',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/KZT': {
    pair: 'NOK/KZT',
    ccy1: 'NOK',
    ccy2: 'KZT',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/NGN': {
    pair: 'NOK/NGN',
    ccy1: 'NOK',
    ccy2: 'NGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/PEN': {
    pair: 'NOK/PEN',
    ccy1: 'NOK',
    ccy2: 'PEN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/PHP': {
    pair: 'NOK/PHP',
    ccy1: 'NOK',
    ccy2: 'PHP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/PLN': {
    pair: 'NOK/PLN',
    ccy1: 'NOK',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/RUB': {
    pair: 'NOK/RUB',
    ccy1: 'NOK',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/SEK': {
    pair: 'NOK/SEK',
    ccy1: 'NOK',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'NOK/TRY': {
    pair: 'NOK/TRY',
    ccy1: 'NOK',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/TWD': {
    pair: 'NOK/TWD',
    ccy1: 'NOK',
    ccy2: 'TWD',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/UAH': {
    pair: 'NOK/UAH',
    ccy1: 'NOK',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/VND': {
    pair: 'NOK/VND',
    ccy1: 'NOK',
    ccy2: 'VND',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/ARS': {
    pair: 'NZD/ARS',
    ccy1: 'NZD',
    ccy2: 'ARS',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/BRL': {
    pair: 'NZD/BRL',
    ccy1: 'NZD',
    ccy2: 'BRL',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/CAD': {
    pair: 'NZD/CAD',
    ccy1: 'NZD',
    ccy2: 'CAD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'NZD/CHF': {
    pair: 'NZD/CHF',
    ccy1: 'NZD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'NZD/CLP': {
    pair: 'NZD/CLP',
    ccy1: 'NZD',
    ccy2: 'CLP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/CNH': {
    pair: 'NZD/CNH',
    ccy1: 'NZD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/CNY': {
    pair: 'NZD/CNY',
    ccy1: 'NZD',
    ccy2: 'CNY',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/COP': {
    pair: 'NZD/COP',
    ccy1: 'NZD',
    ccy2: 'COP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/CZK': {
    pair: 'NZD/CZK',
    ccy1: 'NZD',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/DKK': {
    pair: 'NZD/DKK',
    ccy1: 'NZD',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/EGP': {
    pair: 'NZD/EGP',
    ccy1: 'NZD',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/HKD': {
    pair: 'NZD/HKD',
    ccy1: 'NZD',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/HUF': {
    pair: 'NZD/HUF',
    ccy1: 'NZD',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/IDR': {
    pair: 'NZD/IDR',
    ccy1: 'NZD',
    ccy2: 'IDR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/ILS': {
    pair: 'NZD/ILS',
    ccy1: 'NZD',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/INR': {
    pair: 'NZD/INR',
    ccy1: 'NZD',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/JPY': {
    pair: 'NZD/JPY',
    ccy1: 'NZD',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'NZD/KRW': {
    pair: 'NZD/KRW',
    ccy1: 'NZD',
    ccy2: 'KRW',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/KZT': {
    pair: 'NZD/KZT',
    ccy1: 'NZD',
    ccy2: 'KZT',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/NGN': {
    pair: 'NZD/NGN',
    ccy1: 'NZD',
    ccy2: 'NGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/NOK': {
    pair: 'NZD/NOK',
    ccy1: 'NZD',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'NZD/PEN': {
    pair: 'NZD/PEN',
    ccy1: 'NZD',
    ccy2: 'PEN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/PHP': {
    pair: 'NZD/PHP',
    ccy1: 'NZD',
    ccy2: 'PHP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/PLN': {
    pair: 'NZD/PLN',
    ccy1: 'NZD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/RUB': {
    pair: 'NZD/RUB',
    ccy1: 'NZD',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/SEK': {
    pair: 'NZD/SEK',
    ccy1: 'NZD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'NZD/SGD': {
    pair: 'NZD/SGD',
    ccy1: 'NZD',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'NZD/TRY': {
    pair: 'NZD/TRY',
    ccy1: 'NZD',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/TWD': {
    pair: 'NZD/TWD',
    ccy1: 'NZD',
    ccy2: 'TWD',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/UAH': {
    pair: 'NZD/UAH',
    ccy1: 'NZD',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/USD': {
    pair: 'NZD/USD',
    ccy1: 'NZD',
    ccy2: 'USD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'NZD/VND': {
    pair: 'NZD/VND',
    ccy1: 'NZD',
    ccy2: 'VND',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/JPY': {
    pair: 'PEN/JPY',
    ccy1: 'PEN',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/JPY': {
    pair: 'PHP/JPY',
    ccy1: 'PHP',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/CNH': {
    pair: 'PLN/CNH',
    ccy1: 'PLN',
    ccy2: 'CNH',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/CNY': {
    pair: 'PLN/CNY',
    ccy1: 'PLN',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/DKK': {
    pair: 'PLN/DKK',
    ccy1: 'PLN',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/HKD': {
    pair: 'PLN/HKD',
    ccy1: 'PLN',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/HRK': {
    pair: 'PLN/HRK',
    ccy1: 'PLN',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/HUF': {
    pair: 'PLN/HUF',
    ccy1: 'PLN',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/ILS': {
    pair: 'PLN/ILS',
    ccy1: 'PLN',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/INR': {
    pair: 'PLN/INR',
    ccy1: 'PLN',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/JPY': {
    pair: 'PLN/JPY',
    ccy1: 'PLN',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/MXN': {
    pair: 'PLN/MXN',
    ccy1: 'PLN',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/RON': {
    pair: 'PLN/RON',
    ccy1: 'PLN',
    ccy2: 'RON',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/RUB': {
    pair: 'PLN/RUB',
    ccy1: 'PLN',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/SEK': {
    pair: 'PLN/SEK',
    ccy1: 'PLN',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/SGD': {
    pair: 'PLN/SGD',
    ccy1: 'PLN',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/UAH': {
    pair: 'PLN/UAH',
    ccy1: 'PLN',
    ccy2: 'UAH',
    precision: 4,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/ZAR': {
    pair: 'PLN/ZAR',
    ccy1: 'PLN',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/CNH': {
    pair: 'RON/CNH',
    ccy1: 'RON',
    ccy2: 'CNH',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/RUB': {
    pair: 'RON/RUB',
    ccy1: 'RON',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/ZAR': {
    pair: 'RON/ZAR',
    ccy1: 'RON',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/CZK': {
    pair: 'RUB/CZK',
    ccy1: 'RUB',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/HKD': {
    pair: 'RUB/HKD',
    ccy1: 'RUB',
    ccy2: 'HKD',
    precision: 4,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/HUF': {
    pair: 'RUB/HUF',
    ccy1: 'RUB',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/JPY': {
    pair: 'RUB/JPY',
    ccy1: 'RUB',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/KZT': {
    pair: 'RUB/KZT',
    ccy1: 'RUB',
    ccy2: 'KZT',
    precision: 4,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/SEK': {
    pair: 'RUB/SEK',
    ccy1: 'RUB',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SAR/AED': {
    pair: 'SAR/AED',
    ccy1: 'SAR',
    ccy2: 'AED',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SAR/CHF': {
    pair: 'SAR/CHF',
    ccy1: 'SAR',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SAR/CZK': {
    pair: 'SAR/CZK',
    ccy1: 'SAR',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/ARS': {
    pair: 'SEK/ARS',
    ccy1: 'SEK',
    ccy2: 'ARS',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/BRL': {
    pair: 'SEK/BRL',
    ccy1: 'SEK',
    ccy2: 'BRL',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/CLP': {
    pair: 'SEK/CLP',
    ccy1: 'SEK',
    ccy2: 'CLP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/CNH': {
    pair: 'SEK/CNH',
    ccy1: 'SEK',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/CNY': {
    pair: 'SEK/CNY',
    ccy1: 'SEK',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/COP': {
    pair: 'SEK/COP',
    ccy1: 'SEK',
    ccy2: 'COP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/EGP': {
    pair: 'SEK/EGP',
    ccy1: 'SEK',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/HUF': {
    pair: 'SEK/HUF',
    ccy1: 'SEK',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/IDR': {
    pair: 'SEK/IDR',
    ccy1: 'SEK',
    ccy2: 'IDR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/INR': {
    pair: 'SEK/INR',
    ccy1: 'SEK',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/JPY': {
    pair: 'SEK/JPY',
    ccy1: 'SEK',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'SEK/KRW': {
    pair: 'SEK/KRW',
    ccy1: 'SEK',
    ccy2: 'KRW',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/KZT': {
    pair: 'SEK/KZT',
    ccy1: 'SEK',
    ccy2: 'KZT',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/NGN': {
    pair: 'SEK/NGN',
    ccy1: 'SEK',
    ccy2: 'NGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/PEN': {
    pair: 'SEK/PEN',
    ccy1: 'SEK',
    ccy2: 'PEN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/PHP': {
    pair: 'SEK/PHP',
    ccy1: 'SEK',
    ccy2: 'PHP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/PLN': {
    pair: 'SEK/PLN',
    ccy1: 'SEK',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/RON': {
    pair: 'SEK/RON',
    ccy1: 'SEK',
    ccy2: 'RON',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/THB': {
    pair: 'SEK/THB',
    ccy1: 'SEK',
    ccy2: 'THB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/TWD': {
    pair: 'SEK/TWD',
    ccy1: 'SEK',
    ccy2: 'TWD',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/UAH': {
    pair: 'SEK/UAH',
    ccy1: 'SEK',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/VND': {
    pair: 'SEK/VND',
    ccy1: 'SEK',
    ccy2: 'VND',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/AED': {
    pair: 'SGD/AED',
    ccy1: 'SGD',
    ccy2: 'AED',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/CNH': {
    pair: 'SGD/CNH',
    ccy1: 'SGD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/CNY': {
    pair: 'SGD/CNY',
    ccy1: 'SGD',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/HKD': {
    pair: 'SGD/HKD',
    ccy1: 'SGD',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/HUF': {
    pair: 'SGD/HUF',
    ccy1: 'SGD',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/IDR': {
    pair: 'SGD/IDR',
    ccy1: 'SGD',
    ccy2: 'IDR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/INR': {
    pair: 'SGD/INR',
    ccy1: 'SGD',
    ccy2: 'INR',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/JPY': {
    pair: 'SGD/JPY',
    ccy1: 'SGD',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/KRW': {
    pair: 'SGD/KRW',
    ccy1: 'SGD',
    ccy2: 'KRW',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/NOK': {
    pair: 'SGD/NOK',
    ccy1: 'SGD',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/PHP': {
    pair: 'SGD/PHP',
    ccy1: 'SGD',
    ccy2: 'PHP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/SEK': {
    pair: 'SGD/SEK',
    ccy1: 'SGD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/THB': {
    pair: 'SGD/THB',
    ccy1: 'SGD',
    ccy2: 'THB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/TWD': {
    pair: 'SGD/TWD',
    ccy1: 'SGD',
    ccy2: 'TWD',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'THB/CNH': {
    pair: 'THB/CNH',
    ccy1: 'THB',
    ccy2: 'CNH',
    precision: 6,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'THB/IDR': {
    pair: 'THB/IDR',
    ccy1: 'THB',
    ccy2: 'IDR',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'THB/JPY': {
    pair: 'THB/JPY',
    ccy1: 'THB',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'THB/PLN': {
    pair: 'THB/PLN',
    ccy1: 'THB',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TND/CHF': {
    pair: 'TND/CHF',
    ccy1: 'TND',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TND/PLN': {
    pair: 'TND/PLN',
    ccy1: 'TND',
    ccy2: 'PLN',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/CZK': {
    pair: 'TRY/CZK',
    ccy1: 'TRY',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/HUF': {
    pair: 'TRY/HUF',
    ccy1: 'TRY',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/ILS': {
    pair: 'TRY/ILS',
    ccy1: 'TRY',
    ccy2: 'ILS',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/JPY': {
    pair: 'TRY/JPY',
    ccy1: 'TRY',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/MXN': {
    pair: 'TRY/MXN',
    ccy1: 'TRY',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/PLN': {
    pair: 'TRY/PLN',
    ccy1: 'TRY',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/RUB': {
    pair: 'TRY/RUB',
    ccy1: 'TRY',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/SEK': {
    pair: 'TRY/SEK',
    ccy1: 'TRY',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/ZAR': {
    pair: 'TRY/ZAR',
    ccy1: 'TRY',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/JPY': {
    pair: 'TWD/JPY',
    ccy1: 'TWD',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/AED': {
    pair: 'USD/AED',
    ccy1: 'USD',
    ccy2: 'AED',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/ARS': {
    pair: 'USD/ARS',
    ccy1: 'USD',
    ccy2: 'ARS',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/BGN': {
    pair: 'USD/BGN',
    ccy1: 'USD',
    ccy2: 'BGN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/BHD': {
    pair: 'USD/BHD',
    ccy1: 'USD',
    ccy2: 'BHD',
    precision: 6,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/BRL': {
    pair: 'USD/BRL',
    ccy1: 'USD',
    ccy2: 'BRL',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/BWP': {
    pair: 'USD/BWP',
    ccy1: 'USD',
    ccy2: 'BWP',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/CAD': {
    pair: 'USD/CAD',
    ccy1: 'USD',
    ccy2: 'CAD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'USD/CHF': {
    pair: 'USD/CHF',
    ccy1: 'USD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'USD/CLP': {
    pair: 'USD/CLP',
    ccy1: 'USD',
    ccy2: 'CLP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/CNH': {
    pair: 'USD/CNH',
    ccy1: 'USD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'USD/CNY': {
    pair: 'USD/CNY',
    ccy1: 'USD',
    ccy2: 'CNY',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/COP': {
    pair: 'USD/COP',
    ccy1: 'USD',
    ccy2: 'COP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/CZK': {
    pair: 'USD/CZK',
    ccy1: 'USD',
    ccy2: 'CZK',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'USD/DKK': {
    pair: 'USD/DKK',
    ccy1: 'USD',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/DZD': {
    pair: 'USD/DZD',
    ccy1: 'USD',
    ccy2: 'DZD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/EGP': {
    pair: 'USD/EGP',
    ccy1: 'USD',
    ccy2: 'EGP',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/GHS': {
    pair: 'USD/GHS',
    ccy1: 'USD',
    ccy2: 'GHS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/HKD': {
    pair: 'USD/HKD',
    ccy1: 'USD',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'USD/HRK': {
    pair: 'USD/HRK',
    ccy1: 'USD',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/HUF': {
    pair: 'USD/HUF',
    ccy1: 'USD',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'USD/IDR': {
    pair: 'USD/IDR',
    ccy1: 'USD',
    ccy2: 'IDR',
    precision: 1,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/ILS': {
    pair: 'USD/ILS',
    ccy1: 'USD',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/INR': {
    pair: 'USD/INR',
    ccy1: 'USD',
    ccy2: 'INR',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/ISK': {
    pair: 'USD/ISK',
    ccy1: 'USD',
    ccy2: 'ISK',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/JOD': {
    pair: 'USD/JOD',
    ccy1: 'USD',
    ccy2: 'JOD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/JPY': {
    pair: 'USD/JPY',
    ccy1: 'USD',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'USD/KES': {
    pair: 'USD/KES',
    ccy1: 'USD',
    ccy2: 'KES',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/KRW': {
    pair: 'USD/KRW',
    ccy1: 'USD',
    ccy2: 'KRW',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/KWD': {
    pair: 'USD/KWD',
    ccy1: 'USD',
    ccy2: 'KWD',
    precision: 6,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/KZT': {
    pair: 'USD/KZT',
    ccy1: 'USD',
    ccy2: 'KZT',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/MAD': {
    pair: 'USD/MAD',
    ccy1: 'USD',
    ccy2: 'MAD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/MUR': {
    pair: 'USD/MUR',
    ccy1: 'USD',
    ccy2: 'MUR',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/MXN': {
    pair: 'USD/MXN',
    ccy1: 'USD',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'USD/MZN': {
    pair: 'USD/MZN',
    ccy1: 'USD',
    ccy2: 'MZN',
    precision: 2,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/NGN': {
    pair: 'USD/NGN',
    ccy1: 'USD',
    ccy2: 'NGN',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/NOK': {
    pair: 'USD/NOK',
    ccy1: 'USD',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'USD/OMR': {
    pair: 'USD/OMR',
    ccy1: 'USD',
    ccy2: 'OMR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/PEN': {
    pair: 'USD/PEN',
    ccy1: 'USD',
    ccy2: 'PEN',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/PHP': {
    pair: 'USD/PHP',
    ccy1: 'USD',
    ccy2: 'PHP',
    precision: 3,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/PLN': {
    pair: 'USD/PLN',
    ccy1: 'USD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'USD/QAR': {
    pair: 'USD/QAR',
    ccy1: 'USD',
    ccy2: 'QAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/RON': {
    pair: 'USD/RON',
    ccy1: 'USD',
    ccy2: 'RON',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'USD/RSD': {
    pair: 'USD/RSD',
    ccy1: 'USD',
    ccy2: 'RSD',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/RUB': {
    pair: 'USD/RUB',
    ccy1: 'USD',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'USD/SAR': {
    pair: 'USD/SAR',
    ccy1: 'USD',
    ccy2: 'SAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/SEK': {
    pair: 'USD/SEK',
    ccy1: 'USD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'USD/SGD': {
    pair: 'USD/SGD',
    ccy1: 'USD',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'USD/THB': {
    pair: 'USD/THB',
    ccy1: 'USD',
    ccy2: 'THB',
    precision: 3,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/TND': {
    pair: 'USD/TND',
    ccy1: 'USD',
    ccy2: 'TND',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/TRY': {
    pair: 'USD/TRY',
    ccy1: 'USD',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'USD/TWD': {
    pair: 'USD/TWD',
    ccy1: 'USD',
    ccy2: 'TWD',
    precision: 4,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/UAH': {
    pair: 'USD/UAH',
    ccy1: 'USD',
    ccy2: 'UAH',
    precision: 5,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/VND': {
    pair: 'USD/VND',
    ccy1: 'USD',
    ccy2: 'VND',
    precision: 1,
    isInverted: false,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/XAF': {
    pair: 'USD/XAF',
    ccy1: 'USD',
    ccy2: 'XAF',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/XOF': {
    pair: 'USD/XOF',
    ccy1: 'USD',
    ccy2: 'XOF',
    precision: 4,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/ZAR': {
    pair: 'USD/ZAR',
    ccy1: 'USD',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'ZAR/CNH': {
    pair: 'ZAR/CNH',
    ccy1: 'ZAR',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/JPY': {
    pair: 'ZAR/JPY',
    ccy1: 'ZAR',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/MXN': {
    pair: 'ZAR/MXN',
    ccy1: 'ZAR',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/RUB': {
    pair: 'ZAR/RUB',
    ccy1: 'ZAR',
    ccy2: 'RUB',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/SEK': {
    pair: 'ZAR/SEK',
    ccy1: 'ZAR',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/SGD': {
    pair: 'ZAR/SGD',
    ccy1: 'ZAR',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/AUD': {
    pair: 'AED/AUD',
    ccy1: 'AED',
    ccy2: 'AUD',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/CAD': {
    pair: 'AED/CAD',
    ccy1: 'AED',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/CHF': {
    pair: 'AED/CHF',
    ccy1: 'AED',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/EUR': {
    pair: 'AED/EUR',
    ccy1: 'AED',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/GBP': {
    pair: 'AED/GBP',
    ccy1: 'AED',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/SAR': {
    pair: 'AED/SAR',
    ccy1: 'AED',
    ccy2: 'SAR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/SGD': {
    pair: 'AED/SGD',
    ccy1: 'AED',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AED/USD': {
    pair: 'AED/USD',
    ccy1: 'AED',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/AUD': {
    pair: 'ARS/AUD',
    ccy1: 'ARS',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/CAD': {
    pair: 'ARS/CAD',
    ccy1: 'ARS',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/CHF': {
    pair: 'ARS/CHF',
    ccy1: 'ARS',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/DKK': {
    pair: 'ARS/DKK',
    ccy1: 'ARS',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/EUR': {
    pair: 'ARS/EUR',
    ccy1: 'ARS',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/GBP': {
    pair: 'ARS/GBP',
    ccy1: 'ARS',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/NOK': {
    pair: 'ARS/NOK',
    ccy1: 'ARS',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/NZD': {
    pair: 'ARS/NZD',
    ccy1: 'ARS',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/SEK': {
    pair: 'ARS/SEK',
    ccy1: 'ARS',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ARS/USD': {
    pair: 'ARS/USD',
    ccy1: 'ARS',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/EUR': {
    pair: 'AUD/EUR',
    ccy1: 'AUD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/GBP': {
    pair: 'AUD/GBP',
    ccy1: 'AUD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'AUD/KES': {
    pair: 'AUD/KES',
    ccy1: 'AUD',
    ccy2: 'KES',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: false
  },
  'BGN/CHF': {
    pair: 'BGN/CHF',
    ccy1: 'BGN',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BGN/EUR': {
    pair: 'BGN/EUR',
    ccy1: 'BGN',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BGN/GBP': {
    pair: 'BGN/GBP',
    ccy1: 'BGN',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BGN/JPY': {
    pair: 'BGN/JPY',
    ccy1: 'BGN',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BHD/CAD': {
    pair: 'BHD/CAD',
    ccy1: 'BHD',
    ccy2: 'CAD',
    precision: 6,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BHD/USD': {
    pair: 'BHD/USD',
    ccy1: 'BHD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/AUD': {
    pair: 'BRL/AUD',
    ccy1: 'BRL',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/CAD': {
    pair: 'BRL/CAD',
    ccy1: 'BRL',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/CHF': {
    pair: 'BRL/CHF',
    ccy1: 'BRL',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/CNH': {
    pair: 'BRL/CNH',
    ccy1: 'BRL',
    ccy2: 'CNH',
    precision: 4,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/EUR': {
    pair: 'BRL/EUR',
    ccy1: 'BRL',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/GBP': {
    pair: 'BRL/GBP',
    ccy1: 'BRL',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/HUF': {
    pair: 'BRL/HUF',
    ccy1: 'BRL',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/NOK': {
    pair: 'BRL/NOK',
    ccy1: 'BRL',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/NZD': {
    pair: 'BRL/NZD',
    ccy1: 'BRL',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/SEK': {
    pair: 'BRL/SEK',
    ccy1: 'BRL',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'BRL/USD': {
    pair: 'BRL/USD',
    ccy1: 'BRL',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/AUD': {
    pair: 'CAD/AUD',
    ccy1: 'CAD',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/EUR': {
    pair: 'CAD/EUR',
    ccy1: 'CAD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/GBP': {
    pair: 'CAD/GBP',
    ccy1: 'CAD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/NZD': {
    pair: 'CAD/NZD',
    ccy1: 'CAD',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CAD/USD': {
    pair: 'CAD/USD',
    ccy1: 'CAD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/AUD': {
    pair: 'CHF/AUD',
    ccy1: 'CHF',
    ccy2: 'AUD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/CAD': {
    pair: 'CHF/CAD',
    ccy1: 'CHF',
    ccy2: 'CAD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/EUR': {
    pair: 'CHF/EUR',
    ccy1: 'CHF',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/GBP': {
    pair: 'CHF/GBP',
    ccy1: 'CHF',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/NZD': {
    pair: 'CHF/NZD',
    ccy1: 'CHF',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/SAR': {
    pair: 'CHF/SAR',
    ccy1: 'CHF',
    ccy2: 'SAR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/TND': {
    pair: 'CHF/TND',
    ccy1: 'CHF',
    ccy2: 'TND',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CHF/USD': {
    pair: 'CHF/USD',
    ccy1: 'CHF',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: false
  },
  'CLP/AUD': {
    pair: 'CLP/AUD',
    ccy1: 'CLP',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CLP/CAD': {
    pair: 'CLP/CAD',
    ccy1: 'CLP',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CLP/CHF': {
    pair: 'CLP/CHF',
    ccy1: 'CLP',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CLP/DKK': {
    pair: 'CLP/DKK',
    ccy1: 'CLP',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CLP/EUR': {
    pair: 'CLP/EUR',
    ccy1: 'CLP',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CLP/GBP': {
    pair: 'CLP/GBP',
    ccy1: 'CLP',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CLP/HUF': {
    pair: 'CLP/HUF',
    ccy1: 'CLP',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CLP/JPY': {
    pair: 'CLP/JPY',
    ccy1: 'CLP',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CLP/NOK': {
    pair: 'CLP/NOK',
    ccy1: 'CLP',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CLP/NZD': {
    pair: 'CLP/NZD',
    ccy1: 'CLP',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CLP/SEK': {
    pair: 'CLP/SEK',
    ccy1: 'CLP',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CLP/USD': {
    pair: 'CLP/USD',
    ccy1: 'CLP',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/AED': {
    pair: 'CNH/AED',
    ccy1: 'CNH',
    ccy2: 'AED',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/AUD': {
    pair: 'CNH/AUD',
    ccy1: 'CNH',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/CAD': {
    pair: 'CNH/CAD',
    ccy1: 'CNH',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/CHF': {
    pair: 'CNH/CHF',
    ccy1: 'CNH',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/DKK': {
    pair: 'CNH/DKK',
    ccy1: 'CNH',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/EUR': {
    pair: 'CNH/EUR',
    ccy1: 'CNH',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/GBP': {
    pair: 'CNH/GBP',
    ccy1: 'CNH',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/NOK': {
    pair: 'CNH/NOK',
    ccy1: 'CNH',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/NZD': {
    pair: 'CNH/NZD',
    ccy1: 'CNH',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/PLN': {
    pair: 'CNH/PLN',
    ccy1: 'CNH',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/SEK': {
    pair: 'CNH/SEK',
    ccy1: 'CNH',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/SGD': {
    pair: 'CNH/SGD',
    ccy1: 'CNH',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/THB': {
    pair: 'CNH/THB',
    ccy1: 'CNH',
    ccy2: 'THB',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/USD': {
    pair: 'CNH/USD',
    ccy1: 'CNH',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNH/ZAR': {
    pair: 'CNH/ZAR',
    ccy1: 'CNH',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/AUD': {
    pair: 'CNY/AUD',
    ccy1: 'CNY',
    ccy2: 'AUD',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/CAD': {
    pair: 'CNY/CAD',
    ccy1: 'CNY',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/CHF': {
    pair: 'CNY/CHF',
    ccy1: 'CNY',
    ccy2: 'CHF',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/DKK': {
    pair: 'CNY/DKK',
    ccy1: 'CNY',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/EUR': {
    pair: 'CNY/EUR',
    ccy1: 'CNY',
    ccy2: 'EUR',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/GBP': {
    pair: 'CNY/GBP',
    ccy1: 'CNY',
    ccy2: 'GBP',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/HUF': {
    pair: 'CNY/HUF',
    ccy1: 'CNY',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/NOK': {
    pair: 'CNY/NOK',
    ccy1: 'CNY',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/NZD': {
    pair: 'CNY/NZD',
    ccy1: 'CNY',
    ccy2: 'NZD',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/SEK': {
    pair: 'CNY/SEK',
    ccy1: 'CNY',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CNY/USD': {
    pair: 'CNY/USD',
    ccy1: 'CNY',
    ccy2: 'USD',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/AUD': {
    pair: 'COP/AUD',
    ccy1: 'COP',
    ccy2: 'AUD',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/CAD': {
    pair: 'COP/CAD',
    ccy1: 'COP',
    ccy2: 'CAD',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/CHF': {
    pair: 'COP/CHF',
    ccy1: 'COP',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/DKK': {
    pair: 'COP/DKK',
    ccy1: 'COP',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/EUR': {
    pair: 'COP/EUR',
    ccy1: 'COP',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/GBP': {
    pair: 'COP/GBP',
    ccy1: 'COP',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/HUF': {
    pair: 'COP/HUF',
    ccy1: 'COP',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/JPY': {
    pair: 'COP/JPY',
    ccy1: 'COP',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/NOK': {
    pair: 'COP/NOK',
    ccy1: 'COP',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/NZD': {
    pair: 'COP/NZD',
    ccy1: 'COP',
    ccy2: 'NZD',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/SEK': {
    pair: 'COP/SEK',
    ccy1: 'COP',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'COP/USD': {
    pair: 'COP/USD',
    ccy1: 'COP',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/AUD': {
    pair: 'CZK/AUD',
    ccy1: 'CZK',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/CHF': {
    pair: 'CZK/CHF',
    ccy1: 'CZK',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/DKK': {
    pair: 'CZK/DKK',
    ccy1: 'CZK',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/EUR': {
    pair: 'CZK/EUR',
    ccy1: 'CZK',
    ccy2: 'EUR',
    precision: 6,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/GBP': {
    pair: 'CZK/GBP',
    ccy1: 'CZK',
    ccy2: 'GBP',
    precision: 6,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/HKD': {
    pair: 'CZK/HKD',
    ccy1: 'CZK',
    ccy2: 'HKD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/NOK': {
    pair: 'CZK/NOK',
    ccy1: 'CZK',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/RUB': {
    pair: 'CZK/RUB',
    ccy1: 'CZK',
    ccy2: 'RUB',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'CZK/USD': {
    pair: 'CZK/USD',
    ccy1: 'CZK',
    ccy2: 'USD',
    precision: 6,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/AUD': {
    pair: 'DKK/AUD',
    ccy1: 'DKK',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/BRL': {
    pair: 'DKK/BRL',
    ccy1: 'DKK',
    ccy2: 'BRL',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/CAD': {
    pair: 'DKK/CAD',
    ccy1: 'DKK',
    ccy2: 'CAD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/CHF': {
    pair: 'DKK/CHF',
    ccy1: 'DKK',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/EUR': {
    pair: 'DKK/EUR',
    ccy1: 'DKK',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/GBP': {
    pair: 'DKK/GBP',
    ccy1: 'DKK',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/NOK': {
    pair: 'DKK/NOK',
    ccy1: 'DKK',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/NZD': {
    pair: 'DKK/NZD',
    ccy1: 'DKK',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/PLN': {
    pair: 'DKK/PLN',
    ccy1: 'DKK',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DKK/USD': {
    pair: 'DKK/USD',
    ccy1: 'DKK',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DZD/EUR': {
    pair: 'DZD/EUR',
    ccy1: 'DZD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'DZD/USD': {
    pair: 'DZD/USD',
    ccy1: 'DZD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/AUD': {
    pair: 'EGP/AUD',
    ccy1: 'EGP',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/CAD': {
    pair: 'EGP/CAD',
    ccy1: 'EGP',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/CHF': {
    pair: 'EGP/CHF',
    ccy1: 'EGP',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/DKK': {
    pair: 'EGP/DKK',
    ccy1: 'EGP',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/EUR': {
    pair: 'EGP/EUR',
    ccy1: 'EGP',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/GBP': {
    pair: 'EGP/GBP',
    ccy1: 'EGP',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/HUF': {
    pair: 'EGP/HUF',
    ccy1: 'EGP',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/JPY': {
    pair: 'EGP/JPY',
    ccy1: 'EGP',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/NOK': {
    pair: 'EGP/NOK',
    ccy1: 'EGP',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/NZD': {
    pair: 'EGP/NZD',
    ccy1: 'EGP',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/SEK': {
    pair: 'EGP/SEK',
    ccy1: 'EGP',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EGP/USD': {
    pair: 'EGP/USD',
    ccy1: 'EGP',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'EUR/ZMW': {
    pair: 'EUR/ZMW',
    ccy1: 'EUR',
    ccy2: 'ZMW',
    precision: 4,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/BHD': {
    pair: 'GBP/BHD',
    ccy1: 'GBP',
    ccy2: 'BHD',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: true
  },
  'GBP/EUR': {
    pair: 'GBP/EUR',
    ccy1: 'GBP',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'GBP/XAF': {
    pair: 'GBP/XAF',
    ccy1: 'GBP',
    ccy2: 'XAF',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GBP/XOF': {
    pair: 'GBP/XOF',
    ccy1: 'GBP',
    ccy2: 'XOF',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GHS/CHF': {
    pair: 'GHS/CHF',
    ccy1: 'GHS',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GHS/EUR': {
    pair: 'GHS/EUR',
    ccy1: 'GHS',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GHS/GBP': {
    pair: 'GHS/GBP',
    ccy1: 'GHS',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GHS/JPY': {
    pair: 'GHS/JPY',
    ccy1: 'GHS',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'GHS/USD': {
    pair: 'GHS/USD',
    ccy1: 'GHS',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/AOA': {
    pair: 'HKD/AOA',
    ccy1: 'HKD',
    ccy2: 'AOA',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/AUD': {
    pair: 'HKD/AUD',
    ccy1: 'HKD',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/CAD': {
    pair: 'HKD/CAD',
    ccy1: 'HKD',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/CHF': {
    pair: 'HKD/CHF',
    ccy1: 'HKD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/CNH': {
    pair: 'HKD/CNH',
    ccy1: 'HKD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: true
  },
  'HKD/EUR': {
    pair: 'HKD/EUR',
    ccy1: 'HKD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/GBP': {
    pair: 'HKD/GBP',
    ccy1: 'HKD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/MXN': {
    pair: 'HKD/MXN',
    ccy1: 'HKD',
    ccy2: 'MXN',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/NZD': {
    pair: 'HKD/NZD',
    ccy1: 'HKD',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/PLN': {
    pair: 'HKD/PLN',
    ccy1: 'HKD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/RUB': {
    pair: 'HKD/RUB',
    ccy1: 'HKD',
    ccy2: 'RUB',
    precision: 4,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/SGD': {
    pair: 'HKD/SGD',
    ccy1: 'HKD',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HKD/USD': {
    pair: 'HKD/USD',
    ccy1: 'HKD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HRK/CHF': {
    pair: 'HRK/CHF',
    ccy1: 'HRK',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HRK/CZK': {
    pair: 'HRK/CZK',
    ccy1: 'HRK',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HRK/EUR': {
    pair: 'HRK/EUR',
    ccy1: 'HRK',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HRK/GBP': {
    pair: 'HRK/GBP',
    ccy1: 'HRK',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HRK/HUF': {
    pair: 'HRK/HUF',
    ccy1: 'HRK',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HRK/JPY': {
    pair: 'HRK/JPY',
    ccy1: 'HRK',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HRK/PLN': {
    pair: 'HRK/PLN',
    ccy1: 'HRK',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HRK/USD': {
    pair: 'HRK/USD',
    ccy1: 'HRK',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/ARS': {
    pair: 'HUF/ARS',
    ccy1: 'HUF',
    ccy2: 'ARS',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/AUD': {
    pair: 'HUF/AUD',
    ccy1: 'HUF',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/CAD': {
    pair: 'HUF/CAD',
    ccy1: 'HUF',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/CHF': {
    pair: 'HUF/CHF',
    ccy1: 'HUF',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/DKK': {
    pair: 'HUF/DKK',
    ccy1: 'HUF',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/EUR': {
    pair: 'HUF/EUR',
    ccy1: 'HUF',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/GBP': {
    pair: 'HUF/GBP',
    ccy1: 'HUF',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/HKD': {
    pair: 'HUF/HKD',
    ccy1: 'HUF',
    ccy2: 'HKD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/ILS': {
    pair: 'HUF/ILS',
    ccy1: 'HUF',
    ccy2: 'ILS',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/NOK': {
    pair: 'HUF/NOK',
    ccy1: 'HUF',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/PLN': {
    pair: 'HUF/PLN',
    ccy1: 'HUF',
    ccy2: 'PLN',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/RUB': {
    pair: 'HUF/RUB',
    ccy1: 'HUF',
    ccy2: 'RUB',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/SEK': {
    pair: 'HUF/SEK',
    ccy1: 'HUF',
    ccy2: 'SEK',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/SGD': {
    pair: 'HUF/SGD',
    ccy1: 'HUF',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/TRY': {
    pair: 'HUF/TRY',
    ccy1: 'HUF',
    ccy2: 'TRY',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'HUF/USD': {
    pair: 'HUF/USD',
    ccy1: 'HUF',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/AUD': {
    pair: 'IDR/AUD',
    ccy1: 'IDR',
    ccy2: 'AUD',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/CAD': {
    pair: 'IDR/CAD',
    ccy1: 'IDR',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/CHF': {
    pair: 'IDR/CHF',
    ccy1: 'IDR',
    ccy2: 'CHF',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/CNH': {
    pair: 'IDR/CNH',
    ccy1: 'IDR',
    ccy2: 'CNH',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/DKK': {
    pair: 'IDR/DKK',
    ccy1: 'IDR',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/EUR': {
    pair: 'IDR/EUR',
    ccy1: 'IDR',
    ccy2: 'EUR',
    precision: 10,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/GBP': {
    pair: 'IDR/GBP',
    ccy1: 'IDR',
    ccy2: 'GBP',
    precision: 10,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/HUF': {
    pair: 'IDR/HUF',
    ccy1: 'IDR',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/JPY': {
    pair: 'IDR/JPY',
    ccy1: 'IDR',
    ccy2: 'JPY',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/NOK': {
    pair: 'IDR/NOK',
    ccy1: 'IDR',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/NZD': {
    pair: 'IDR/NZD',
    ccy1: 'IDR',
    ccy2: 'NZD',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/SEK': {
    pair: 'IDR/SEK',
    ccy1: 'IDR',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'IDR/USD': {
    pair: 'IDR/USD',
    ccy1: 'IDR',
    ccy2: 'USD',
    precision: 10,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ILS/CAD': {
    pair: 'ILS/CAD',
    ccy1: 'ILS',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ILS/CHF': {
    pair: 'ILS/CHF',
    ccy1: 'ILS',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ILS/EUR': {
    pair: 'ILS/EUR',
    ccy1: 'ILS',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ILS/GBP': {
    pair: 'ILS/GBP',
    ccy1: 'ILS',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ILS/NZD': {
    pair: 'ILS/NZD',
    ccy1: 'ILS',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ILS/PLN': {
    pair: 'ILS/PLN',
    ccy1: 'ILS',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ILS/USD': {
    pair: 'ILS/USD',
    ccy1: 'ILS',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/AUD': {
    pair: 'INR/AUD',
    ccy1: 'INR',
    ccy2: 'AUD',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/CAD': {
    pair: 'INR/CAD',
    ccy1: 'INR',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/CHF': {
    pair: 'INR/CHF',
    ccy1: 'INR',
    ccy2: 'CHF',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/CNH': {
    pair: 'INR/CNH',
    ccy1: 'INR',
    ccy2: 'CNH',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/DKK': {
    pair: 'INR/DKK',
    ccy1: 'INR',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/EUR': {
    pair: 'INR/EUR',
    ccy1: 'INR',
    ccy2: 'EUR',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/GBP': {
    pair: 'INR/GBP',
    ccy1: 'INR',
    ccy2: 'GBP',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/HUF': {
    pair: 'INR/HUF',
    ccy1: 'INR',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/NOK': {
    pair: 'INR/NOK',
    ccy1: 'INR',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/NZD': {
    pair: 'INR/NZD',
    ccy1: 'INR',
    ccy2: 'NZD',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/SEK': {
    pair: 'INR/SEK',
    ccy1: 'INR',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/SGD': {
    pair: 'INR/SGD',
    ccy1: 'INR',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'INR/USD': {
    pair: 'INR/USD',
    ccy1: 'INR',
    ccy2: 'USD',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/AED': {
    pair: 'JPY/AED',
    ccy1: 'JPY',
    ccy2: 'AED',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/ARS': {
    pair: 'JPY/ARS',
    ccy1: 'JPY',
    ccy2: 'ARS',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/AUD': {
    pair: 'JPY/AUD',
    ccy1: 'JPY',
    ccy2: 'AUD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/BRL': {
    pair: 'JPY/BRL',
    ccy1: 'JPY',
    ccy2: 'BRL',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/CAD': {
    pair: 'JPY/CAD',
    ccy1: 'JPY',
    ccy2: 'CAD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/CHF': {
    pair: 'JPY/CHF',
    ccy1: 'JPY',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/CNH': {
    pair: 'JPY/CNH',
    ccy1: 'JPY',
    ccy2: 'CNH',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/CNY': {
    pair: 'JPY/CNY',
    ccy1: 'JPY',
    ccy2: 'CNY',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/CZK': {
    pair: 'JPY/CZK',
    ccy1: 'JPY',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/DKK': {
    pair: 'JPY/DKK',
    ccy1: 'JPY',
    ccy2: 'DKK',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/EUR': {
    pair: 'JPY/EUR',
    ccy1: 'JPY',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/GBP': {
    pair: 'JPY/GBP',
    ccy1: 'JPY',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/HKD': {
    pair: 'JPY/HKD',
    ccy1: 'JPY',
    ccy2: 'HKD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/HUF': {
    pair: 'JPY/HUF',
    ccy1: 'JPY',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/ILS': {
    pair: 'JPY/ILS',
    ccy1: 'JPY',
    ccy2: 'ILS',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/MXN': {
    pair: 'JPY/MXN',
    ccy1: 'JPY',
    ccy2: 'MXN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/NOK': {
    pair: 'JPY/NOK',
    ccy1: 'JPY',
    ccy2: 'NOK',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/NZD': {
    pair: 'JPY/NZD',
    ccy1: 'JPY',
    ccy2: 'NZD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/PEN': {
    pair: 'JPY/PEN',
    ccy1: 'JPY',
    ccy2: 'PEN',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/PHP': {
    pair: 'JPY/PHP',
    ccy1: 'JPY',
    ccy2: 'PHP',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/PLN': {
    pair: 'JPY/PLN',
    ccy1: 'JPY',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/RUB': {
    pair: 'JPY/RUB',
    ccy1: 'JPY',
    ccy2: 'RUB',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/SEK': {
    pair: 'JPY/SEK',
    ccy1: 'JPY',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/SGD': {
    pair: 'JPY/SGD',
    ccy1: 'JPY',
    ccy2: 'SGD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/THB': {
    pair: 'JPY/THB',
    ccy1: 'JPY',
    ccy2: 'THB',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/TRY': {
    pair: 'JPY/TRY',
    ccy1: 'JPY',
    ccy2: 'TRY',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/TWD': {
    pair: 'JPY/TWD',
    ccy1: 'JPY',
    ccy2: 'TWD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/USD': {
    pair: 'JPY/USD',
    ccy1: 'JPY',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: false
  },
  'JPY/XAF': {
    pair: 'JPY/XAF',
    ccy1: 'JPY',
    ccy2: 'XAF',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/XOF': {
    pair: 'JPY/XOF',
    ccy1: 'JPY',
    ccy2: 'XOF',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'JPY/ZAR': {
    pair: 'JPY/ZAR',
    ccy1: 'JPY',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KES/AUD': {
    pair: 'KES/AUD',
    ccy1: 'KES',
    ccy2: 'AUD',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: false
  },
  'KES/EUR': {
    pair: 'KES/EUR',
    ccy1: 'KES',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KES/GBP': {
    pair: 'KES/GBP',
    ccy1: 'KES',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KES/JPY': {
    pair: 'KES/JPY',
    ccy1: 'KES',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/AUD': {
    pair: 'KRW/AUD',
    ccy1: 'KRW',
    ccy2: 'AUD',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/CAD': {
    pair: 'KRW/CAD',
    ccy1: 'KRW',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/CHF': {
    pair: 'KRW/CHF',
    ccy1: 'KRW',
    ccy2: 'CHF',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/CNH': {
    pair: 'KRW/CNH',
    ccy1: 'KRW',
    ccy2: 'CNH',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/DKK': {
    pair: 'KRW/DKK',
    ccy1: 'KRW',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/EUR': {
    pair: 'KRW/EUR',
    ccy1: 'KRW',
    ccy2: 'EUR',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/GBP': {
    pair: 'KRW/GBP',
    ccy1: 'KRW',
    ccy2: 'GBP',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/HUF': {
    pair: 'KRW/HUF',
    ccy1: 'KRW',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/JPY': {
    pair: 'KRW/JPY',
    ccy1: 'KRW',
    ccy2: 'JPY',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/NOK': {
    pair: 'KRW/NOK',
    ccy1: 'KRW',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/NZD': {
    pair: 'KRW/NZD',
    ccy1: 'KRW',
    ccy2: 'NZD',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/SEK': {
    pair: 'KRW/SEK',
    ccy1: 'KRW',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KRW/USD': {
    pair: 'KRW/USD',
    ccy1: 'KRW',
    ccy2: 'USD',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KWD/CAD': {
    pair: 'KWD/CAD',
    ccy1: 'KWD',
    ccy2: 'CAD',
    precision: 6,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KWD/CHF': {
    pair: 'KWD/CHF',
    ccy1: 'KWD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KWD/JPY': {
    pair: 'KWD/JPY',
    ccy1: 'KWD',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KWD/USD': {
    pair: 'KWD/USD',
    ccy1: 'KWD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/AUD': {
    pair: 'KZT/AUD',
    ccy1: 'KZT',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/CAD': {
    pair: 'KZT/CAD',
    ccy1: 'KZT',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/CHF': {
    pair: 'KZT/CHF',
    ccy1: 'KZT',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/DKK': {
    pair: 'KZT/DKK',
    ccy1: 'KZT',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/EUR': {
    pair: 'KZT/EUR',
    ccy1: 'KZT',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/GBP': {
    pair: 'KZT/GBP',
    ccy1: 'KZT',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/HUF': {
    pair: 'KZT/HUF',
    ccy1: 'KZT',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/JPY': {
    pair: 'KZT/JPY',
    ccy1: 'KZT',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/NOK': {
    pair: 'KZT/NOK',
    ccy1: 'KZT',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/NZD': {
    pair: 'KZT/NZD',
    ccy1: 'KZT',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/RUB': {
    pair: 'KZT/RUB',
    ccy1: 'KZT',
    ccy2: 'RUB',
    precision: 4,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/SEK': {
    pair: 'KZT/SEK',
    ccy1: 'KZT',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'KZT/USD': {
    pair: 'KZT/USD',
    ccy1: 'KZT',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MAD/CHF': {
    pair: 'MAD/CHF',
    ccy1: 'MAD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MAD/EUR': {
    pair: 'MAD/EUR',
    ccy1: 'MAD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MAD/GBP': {
    pair: 'MAD/GBP',
    ccy1: 'MAD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MAD/JPY': {
    pair: 'MAD/JPY',
    ccy1: 'MAD',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MAD/USD': {
    pair: 'MAD/USD',
    ccy1: 'MAD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MUR/EUR': {
    pair: 'MUR/EUR',
    ccy1: 'MUR',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MUR/USD': {
    pair: 'MUR/USD',
    ccy1: 'MUR',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MXN/CAD': {
    pair: 'MXN/CAD',
    ccy1: 'MXN',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MXN/CHF': {
    pair: 'MXN/CHF',
    ccy1: 'MXN',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MXN/CNH': {
    pair: 'MXN/CNH',
    ccy1: 'MXN',
    ccy2: 'CNH',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: false
  },
  'MXN/EUR': {
    pair: 'MXN/EUR',
    ccy1: 'MXN',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MXN/PLN': {
    pair: 'MXN/PLN',
    ccy1: 'MXN',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MXN/TRY': {
    pair: 'MXN/TRY',
    ccy1: 'MXN',
    ccy2: 'TRY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MXN/USD': {
    pair: 'MXN/USD',
    ccy1: 'MXN',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'MXN/ZAR': {
    pair: 'MXN/ZAR',
    ccy1: 'MXN',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/AUD': {
    pair: 'NGN/AUD',
    ccy1: 'NGN',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/CAD': {
    pair: 'NGN/CAD',
    ccy1: 'NGN',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/CHF': {
    pair: 'NGN/CHF',
    ccy1: 'NGN',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/DKK': {
    pair: 'NGN/DKK',
    ccy1: 'NGN',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/EUR': {
    pair: 'NGN/EUR',
    ccy1: 'NGN',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/GBP': {
    pair: 'NGN/GBP',
    ccy1: 'NGN',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/HUF': {
    pair: 'NGN/HUF',
    ccy1: 'NGN',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/JPY': {
    pair: 'NGN/JPY',
    ccy1: 'NGN',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/NOK': {
    pair: 'NGN/NOK',
    ccy1: 'NGN',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/NZD': {
    pair: 'NGN/NZD',
    ccy1: 'NGN',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/SEK': {
    pair: 'NGN/SEK',
    ccy1: 'NGN',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NGN/USD': {
    pair: 'NGN/USD',
    ccy1: 'NGN',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/AUD': {
    pair: 'NOK/AUD',
    ccy1: 'NOK',
    ccy2: 'AUD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/CAD': {
    pair: 'NOK/CAD',
    ccy1: 'NOK',
    ccy2: 'CAD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/CHF': {
    pair: 'NOK/CHF',
    ccy1: 'NOK',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/EUR': {
    pair: 'NOK/EUR',
    ccy1: 'NOK',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/GBP': {
    pair: 'NOK/GBP',
    ccy1: 'NOK',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/NZD': {
    pair: 'NOK/NZD',
    ccy1: 'NOK',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/SGD': {
    pair: 'NOK/SGD',
    ccy1: 'NOK',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NOK/USD': {
    pair: 'NOK/USD',
    ccy1: 'NOK',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/AUD': {
    pair: 'NZD/AUD',
    ccy1: 'NZD',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/EUR': {
    pair: 'NZD/EUR',
    ccy1: 'NZD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'NZD/GBP': {
    pair: 'NZD/GBP',
    ccy1: 'NZD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'OMR/EUR': {
    pair: 'OMR/EUR',
    ccy1: 'OMR',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'OMR/USD': {
    pair: 'OMR/USD',
    ccy1: 'OMR',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/AUD': {
    pair: 'PEN/AUD',
    ccy1: 'PEN',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/CAD': {
    pair: 'PEN/CAD',
    ccy1: 'PEN',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/CHF': {
    pair: 'PEN/CHF',
    ccy1: 'PEN',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/DKK': {
    pair: 'PEN/DKK',
    ccy1: 'PEN',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/EUR': {
    pair: 'PEN/EUR',
    ccy1: 'PEN',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/GBP': {
    pair: 'PEN/GBP',
    ccy1: 'PEN',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/HUF': {
    pair: 'PEN/HUF',
    ccy1: 'PEN',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/NOK': {
    pair: 'PEN/NOK',
    ccy1: 'PEN',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/NZD': {
    pair: 'PEN/NZD',
    ccy1: 'PEN',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/SEK': {
    pair: 'PEN/SEK',
    ccy1: 'PEN',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PEN/USD': {
    pair: 'PEN/USD',
    ccy1: 'PEN',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/AUD': {
    pair: 'PHP/AUD',
    ccy1: 'PHP',
    ccy2: 'AUD',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/CAD': {
    pair: 'PHP/CAD',
    ccy1: 'PHP',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/CHF': {
    pair: 'PHP/CHF',
    ccy1: 'PHP',
    ccy2: 'CHF',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/CNH': {
    pair: 'PHP/CNH',
    ccy1: 'PHP',
    ccy2: 'CNH',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/DKK': {
    pair: 'PHP/DKK',
    ccy1: 'PHP',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/EUR': {
    pair: 'PHP/EUR',
    ccy1: 'PHP',
    ccy2: 'EUR',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/GBP': {
    pair: 'PHP/GBP',
    ccy1: 'PHP',
    ccy2: 'GBP',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/HUF': {
    pair: 'PHP/HUF',
    ccy1: 'PHP',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/NOK': {
    pair: 'PHP/NOK',
    ccy1: 'PHP',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/NZD': {
    pair: 'PHP/NZD',
    ccy1: 'PHP',
    ccy2: 'NZD',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/SEK': {
    pair: 'PHP/SEK',
    ccy1: 'PHP',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PHP/USD': {
    pair: 'PHP/USD',
    ccy1: 'PHP',
    ccy2: 'USD',
    precision: 8,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/AED': {
    pair: 'PLN/AED',
    ccy1: 'PLN',
    ccy2: 'AED',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/AUD': {
    pair: 'PLN/AUD',
    ccy1: 'PLN',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/BGN': {
    pair: 'PLN/BGN',
    ccy1: 'PLN',
    ccy2: 'BGN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/CHF': {
    pair: 'PLN/CHF',
    ccy1: 'PLN',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/CZK': {
    pair: 'PLN/CZK',
    ccy1: 'PLN',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/EUR': {
    pair: 'PLN/EUR',
    ccy1: 'PLN',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/GBP': {
    pair: 'PLN/GBP',
    ccy1: 'PLN',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/THB': {
    pair: 'PLN/THB',
    ccy1: 'PLN',
    ccy2: 'THB',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/TND': {
    pair: 'PLN/TND',
    ccy1: 'PLN',
    ccy2: 'TND',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/TRY': {
    pair: 'PLN/TRY',
    ccy1: 'PLN',
    ccy2: 'TRY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'PLN/USD': {
    pair: 'PLN/USD',
    ccy1: 'PLN',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'QAR/CHF': {
    pair: 'QAR/CHF',
    ccy1: 'QAR',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'QAR/JPY': {
    pair: 'QAR/JPY',
    ccy1: 'QAR',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'QAR/USD': {
    pair: 'QAR/USD',
    ccy1: 'QAR',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/BGN': {
    pair: 'RON/BGN',
    ccy1: 'RON',
    ccy2: 'BGN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/CAD': {
    pair: 'RON/CAD',
    ccy1: 'RON',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/CHF': {
    pair: 'RON/CHF',
    ccy1: 'RON',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/CZK': {
    pair: 'RON/CZK',
    ccy1: 'RON',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/EUR': {
    pair: 'RON/EUR',
    ccy1: 'RON',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/HUF': {
    pair: 'RON/HUF',
    ccy1: 'RON',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/JPY': {
    pair: 'RON/JPY',
    ccy1: 'RON',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/PLN': {
    pair: 'RON/PLN',
    ccy1: 'RON',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RON/USD': {
    pair: 'RON/USD',
    ccy1: 'RON',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RSD/CHF': {
    pair: 'RSD/CHF',
    ccy1: 'RSD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RSD/JPY': {
    pair: 'RSD/JPY',
    ccy1: 'RSD',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/AUD': {
    pair: 'RUB/AUD',
    ccy1: 'RUB',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/CAD': {
    pair: 'RUB/CAD',
    ccy1: 'RUB',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/CHF': {
    pair: 'RUB/CHF',
    ccy1: 'RUB',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/CNH': {
    pair: 'RUB/CNH',
    ccy1: 'RUB',
    ccy2: 'CNH',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/DKK': {
    pair: 'RUB/DKK',
    ccy1: 'RUB',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/EUR': {
    pair: 'RUB/EUR',
    ccy1: 'RUB',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/GBP': {
    pair: 'RUB/GBP',
    ccy1: 'RUB',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/NOK': {
    pair: 'RUB/NOK',
    ccy1: 'RUB',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/NZD': {
    pair: 'RUB/NZD',
    ccy1: 'RUB',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/PLN': {
    pair: 'RUB/PLN',
    ccy1: 'RUB',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/RON': {
    pair: 'RUB/RON',
    ccy1: 'RUB',
    ccy2: 'RON',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/TRY': {
    pair: 'RUB/TRY',
    ccy1: 'RUB',
    ccy2: 'TRY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/USD': {
    pair: 'RUB/USD',
    ccy1: 'RUB',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'RUB/ZAR': {
    pair: 'RUB/ZAR',
    ccy1: 'RUB',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SAR/CAD': {
    pair: 'SAR/CAD',
    ccy1: 'SAR',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SAR/EUR': {
    pair: 'SAR/EUR',
    ccy1: 'SAR',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SAR/GBP': {
    pair: 'SAR/GBP',
    ccy1: 'SAR',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SAR/JPY': {
    pair: 'SAR/JPY',
    ccy1: 'SAR',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SAR/USD': {
    pair: 'SAR/USD',
    ccy1: 'SAR',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/AED': {
    pair: 'SEK/AED',
    ccy1: 'SEK',
    ccy2: 'AED',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/AUD': {
    pair: 'SEK/AUD',
    ccy1: 'SEK',
    ccy2: 'AUD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/CAD': {
    pair: 'SEK/CAD',
    ccy1: 'SEK',
    ccy2: 'CAD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/CHF': {
    pair: 'SEK/CHF',
    ccy1: 'SEK',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/CZK': {
    pair: 'SEK/CZK',
    ccy1: 'SEK',
    ccy2: 'CZK',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/DKK': {
    pair: 'SEK/DKK',
    ccy1: 'SEK',
    ccy2: 'DKK',
    precision: 6,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/EUR': {
    pair: 'SEK/EUR',
    ccy1: 'SEK',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/GBP': {
    pair: 'SEK/GBP',
    ccy1: 'SEK',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/HKD': {
    pair: 'SEK/HKD',
    ccy1: 'SEK',
    ccy2: 'HKD',
    precision: 6,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/MAD': {
    pair: 'SEK/MAD',
    ccy1: 'SEK',
    ccy2: 'MAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/NOK': {
    pair: 'SEK/NOK',
    ccy1: 'SEK',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/NZD': {
    pair: 'SEK/NZD',
    ccy1: 'SEK',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/RUB': {
    pair: 'SEK/RUB',
    ccy1: 'SEK',
    ccy2: 'RUB',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/SGD': {
    pair: 'SEK/SGD',
    ccy1: 'SEK',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/TRY': {
    pair: 'SEK/TRY',
    ccy1: 'SEK',
    ccy2: 'TRY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/USD': {
    pair: 'SEK/USD',
    ccy1: 'SEK',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SEK/ZAR': {
    pair: 'SEK/ZAR',
    ccy1: 'SEK',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/AUD': {
    pair: 'SGD/AUD',
    ccy1: 'SGD',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/CAD': {
    pair: 'SGD/CAD',
    ccy1: 'SGD',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/CHF': {
    pair: 'SGD/CHF',
    ccy1: 'SGD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/CZK': {
    pair: 'SGD/CZK',
    ccy1: 'SGD',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/DKK': {
    pair: 'SGD/DKK',
    ccy1: 'SGD',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/EUR': {
    pair: 'SGD/EUR',
    ccy1: 'SGD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/GBP': {
    pair: 'SGD/GBP',
    ccy1: 'SGD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/NZD': {
    pair: 'SGD/NZD',
    ccy1: 'SGD',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/PLN': {
    pair: 'SGD/PLN',
    ccy1: 'SGD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/RUB': {
    pair: 'SGD/RUB',
    ccy1: 'SGD',
    ccy2: 'RUB',
    precision: 4,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: false
  },
  'SGD/USD': {
    pair: 'SGD/USD',
    ccy1: 'SGD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'SGD/ZAR': {
    pair: 'SGD/ZAR',
    ccy1: 'SGD',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'THB/CAD': {
    pair: 'THB/CAD',
    ccy1: 'THB',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'THB/CHF': {
    pair: 'THB/CHF',
    ccy1: 'THB',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'THB/EUR': {
    pair: 'THB/EUR',
    ccy1: 'THB',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'THB/HKD': {
    pair: 'THB/HKD',
    ccy1: 'THB',
    ccy2: 'HKD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'THB/USD': {
    pair: 'THB/USD',
    ccy1: 'THB',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TND/EUR': {
    pair: 'TND/EUR',
    ccy1: 'TND',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TND/GBP': {
    pair: 'TND/GBP',
    ccy1: 'TND',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TND/JPY': {
    pair: 'TND/JPY',
    ccy1: 'TND',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TND/USD': {
    pair: 'TND/USD',
    ccy1: 'TND',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/AUD': {
    pair: 'TRY/AUD',
    ccy1: 'TRY',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/CAD': {
    pair: 'TRY/CAD',
    ccy1: 'TRY',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/CHF': {
    pair: 'TRY/CHF',
    ccy1: 'TRY',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/DKK': {
    pair: 'TRY/DKK',
    ccy1: 'TRY',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/EUR': {
    pair: 'TRY/EUR',
    ccy1: 'TRY',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/GBP': {
    pair: 'TRY/GBP',
    ccy1: 'TRY',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/NOK': {
    pair: 'TRY/NOK',
    ccy1: 'TRY',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/NZD': {
    pair: 'TRY/NZD',
    ccy1: 'TRY',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TRY/USD': {
    pair: 'TRY/USD',
    ccy1: 'TRY',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/AUD': {
    pair: 'TWD/AUD',
    ccy1: 'TWD',
    ccy2: 'AUD',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/CAD': {
    pair: 'TWD/CAD',
    ccy1: 'TWD',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/CHF': {
    pair: 'TWD/CHF',
    ccy1: 'TWD',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/CNH': {
    pair: 'TWD/CNH',
    ccy1: 'TWD',
    ccy2: 'CNH',
    precision: 6,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/DKK': {
    pair: 'TWD/DKK',
    ccy1: 'TWD',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/EUR': {
    pair: 'TWD/EUR',
    ccy1: 'TWD',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/GBP': {
    pair: 'TWD/GBP',
    ccy1: 'TWD',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/HKD': {
    pair: 'TWD/HKD',
    ccy1: 'TWD',
    ccy2: 'HKD',
    precision: 4,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/HUF': {
    pair: 'TWD/HUF',
    ccy1: 'TWD',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/NOK': {
    pair: 'TWD/NOK',
    ccy1: 'TWD',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/NZD': {
    pair: 'TWD/NZD',
    ccy1: 'TWD',
    ccy2: 'NZD',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/SEK': {
    pair: 'TWD/SEK',
    ccy1: 'TWD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'TWD/USD': {
    pair: 'TWD/USD',
    ccy1: 'TWD',
    ccy2: 'USD',
    precision: 7,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/AUD': {
    pair: 'UAH/AUD',
    ccy1: 'UAH',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/CAD': {
    pair: 'UAH/CAD',
    ccy1: 'UAH',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/CHF': {
    pair: 'UAH/CHF',
    ccy1: 'UAH',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/DKK': {
    pair: 'UAH/DKK',
    ccy1: 'UAH',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/EUR': {
    pair: 'UAH/EUR',
    ccy1: 'UAH',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/GBP': {
    pair: 'UAH/GBP',
    ccy1: 'UAH',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/HUF': {
    pair: 'UAH/HUF',
    ccy1: 'UAH',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/JPY': {
    pair: 'UAH/JPY',
    ccy1: 'UAH',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/NOK': {
    pair: 'UAH/NOK',
    ccy1: 'UAH',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/NZD': {
    pair: 'UAH/NZD',
    ccy1: 'UAH',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/PLN': {
    pair: 'UAH/PLN',
    ccy1: 'UAH',
    ccy2: 'PLN',
    precision: 4,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/SEK': {
    pair: 'UAH/SEK',
    ccy1: 'UAH',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UAH/USD': {
    pair: 'UAH/USD',
    ccy1: 'UAH',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/AUD': {
    pair: 'USD/AUD',
    ccy1: 'USD',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/EUR': {
    pair: 'USD/EUR',
    ccy1: 'USD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/GBP': {
    pair: 'USD/GBP',
    ccy1: 'USD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/NZD': {
    pair: 'USD/NZD',
    ccy1: 'USD',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/UGX': {
    pair: 'USD/UGX',
    ccy1: 'USD',
    ccy2: 'UGX',
    precision: 4,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/UZS': {
    pair: 'USD/UZS',
    ccy1: 'USD',
    ccy2: 'UZS',
    precision: 4,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'USD/ZMW': {
    pair: 'USD/ZMW',
    ccy1: 'USD',
    ccy2: 'ZMW',
    precision: 4,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'UZS/USD': {
    pair: 'UZS/USD',
    ccy1: 'UZS',
    ccy2: 'USD',
    precision: 4,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/AUD': {
    pair: 'VND/AUD',
    ccy1: 'VND',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/CAD': {
    pair: 'VND/CAD',
    ccy1: 'VND',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/CHF': {
    pair: 'VND/CHF',
    ccy1: 'VND',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/DKK': {
    pair: 'VND/DKK',
    ccy1: 'VND',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/EUR': {
    pair: 'VND/EUR',
    ccy1: 'VND',
    ccy2: 'EUR',
    precision: 9,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/GBP': {
    pair: 'VND/GBP',
    ccy1: 'VND',
    ccy2: 'GBP',
    precision: 9,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/HUF': {
    pair: 'VND/HUF',
    ccy1: 'VND',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/JPY': {
    pair: 'VND/JPY',
    ccy1: 'VND',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/NOK': {
    pair: 'VND/NOK',
    ccy1: 'VND',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/NZD': {
    pair: 'VND/NZD',
    ccy1: 'VND',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/SEK': {
    pair: 'VND/SEK',
    ccy1: 'VND',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'VND/USD': {
    pair: 'VND/USD',
    ccy1: 'VND',
    ccy2: 'USD',
    precision: 9,
    isInverted: true,
    canBeDelivered: false,
    notDeliverable: true,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/CAD': {
    pair: 'ZAR/CAD',
    ccy1: 'ZAR',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/CHF': {
    pair: 'ZAR/CHF',
    ccy1: 'ZAR',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/CZK': {
    pair: 'ZAR/CZK',
    ccy1: 'ZAR',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/EUR': {
    pair: 'ZAR/EUR',
    ccy1: 'ZAR',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/GBP': {
    pair: 'ZAR/GBP',
    ccy1: 'ZAR',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/HUF': {
    pair: 'ZAR/HUF',
    ccy1: 'ZAR',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/PLN': {
    pair: 'ZAR/PLN',
    ccy1: 'ZAR',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/RON': {
    pair: 'ZAR/RON',
    ccy1: 'ZAR',
    ccy2: 'RON',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/TRY': {
    pair: 'ZAR/TRY',
    ccy1: 'ZAR',
    ccy2: 'TRY',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: false,
    isOptionCurrency: false
  },
  'ZAR/USD': {
    pair: 'ZAR/USD',
    ccy1: 'ZAR',
    ccy2: 'USD',
    precision: 5,
    isInverted: true,
    canBeDelivered: true,
    notDeliverable: false,
    isCashDeliverable: true,
    isOptionCurrency: false
  }
};
